import React, { useRef, useState } from "react";
import { Row, Col, InputGroup, FormControl, Form, Modal, Alert } from "react-bootstrap";
import logo from "../img/LogoH.png";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/loaderButton";
import { BiMessageSquareError } from "react-icons/bi";
import { useHistory } from "react-router";
import { connect } from "react-redux";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    setLoginUser(auth_userid){
        dispatch({
            type: "USER_PERFORM_LOGIN",
            auth_userid
        })
    },
});

const Login = ({setLoginUser}) => {   
    const [isLoading, setIsLoading] = useState(false);
    const [usuario, setUsuario] = useState("");
    const [clave, setClave] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [alerType, setAlerType] = useState("");
    const [alertMSG, setAlertMsg] = useState("");
    const [showModalNewPass, setShowModalNewPass] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [newPassConfirm, setNewPassConfirm] = useState("");
    const [isLoadingNewPass, setIsLoadingNewPass] = useState(false);
    const [alertShow, setAlertShow] = useState(false);

    const modalErrorTitle = useRef(<BiMessageSquareError size={30}/>);
    const modalErrorMsg = useRef("");
    const login = useRef(null);

    const history = useHistory();

    const handleErrors = (error) => {
        switch (error.code) {
            case "NotAuthorizedException":
                modalErrorMsg.current = "Nombre de usuario o clave incorrectos";
                setShowModalError(true);
                break;
            case "UserNotFoundException":
                modalErrorMsg.current = "Nombre de usuario o clave incorrectos";
                setShowModalError(true);
                break;
            case "NetworkError":
                modalErrorMsg.current = "No es posible iniciar sesión, compruebe su conexión a internet, e intentelo de nuevo";
                setShowModalError(true);
                break;
            default:
                console.log(error);
                modalErrorMsg.current = "Error, intentalo de nuevo, si el problema persite comunicate con nosotros";
                setShowModalError(true);
                break;
        }
    }

    const enableLogin = () => {
        return usuario.length > 3 &&
        usuario.includes("@") &&
        clave.length > 4
    };

    const handleLogin = async() => {
        setShowModalError(false);
        setIsLoading(true);
        try {
            login.current = await Auth.signIn(usuario, clave);
            if (login.current.challengeName === 'NEW_PASSWORD_REQUIRED'){
                console.log("cambio de clave requerido");
                setAlerType("info");
                setAlertMsg([<Alert.Heading key="002">Ten en cuenta que:</Alert.Heading>,
                    <p key="001">
                        La contraseña debe tener mínimo 8 caracteres, debe incluir numeros, 
                        letras mayúsculas, minúsculas y caracteres especiales (*,#)
                    </p>]);
                setAlertShow(true);
                setShowModalNewPass(true);
            } else {
                setLoginUser(login.current.username)
                history.push("/");
            }
        } catch (error){
            handleErrors(error);
            setIsLoading(false);
        }
    };

    const handleCloseModalNewPass = () => {
        setShowModalNewPass(false);
        setNewPass("");
        setNewPassConfirm("");
        setIsLoadingNewPass(false);
        setAlertMsg("");
        setAlerType("");
        setIsLoading(false);
        setAlertShow(false);
    };

    const handleEnabledConfirmNewPass = () => {
        return newPass.length > 7 && 
        newPassConfirm === newPass;
    };

    const handleConfirmNewPass = async() => {
        setIsLoadingNewPass(true);
        setShowModalNewPass(false);
        setNewPass("");
        setNewPassConfirm("");
        setIsLoadingNewPass(false);
        setAlertMsg("");
        setAlerType("");
        try {
            login.current = await Auth.completeNewPassword(
                login.current,
                newPass
            );
            history.push("/");
        } catch(error){
            handleErrors(error);
            setIsLoading(false);
        }
    };

    return (
    <div>
        <Modal 
            show={showModalNewPass}
            onHide={handleCloseModalNewPass}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title >Asignar clave</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Label>Vamos a asignar una nueva clave para tu cuenta</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="newPass">Clave</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Nueva clave"
                                    value={newPass}
                                    type="password"
                                    onChange={e=>setNewPass(e.target.value)}
                                    aria-describedby="newPass"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="newPass">Confirmación</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Ingresa tu nueva clave de nuevo"
                                    value={newPassConfirm}
                                    type="password"
                                    onChange={e=>setNewPassConfirm(e.target.value)}
                                    aria-describedby="newPassConfirm"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert show={alertShow} variant={alerType}>
                                {alertMSG}
                            </Alert>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <LoaderButton
                        onClick={()=>handleConfirmNewPass()}
                        isLoading={isLoadingNewPass}
                        disabled={!handleEnabledConfirmNewPass()}
                        variant="outline-primary"
                    > Ingresar</LoaderButton>                
                </Modal.Footer>
            </Modal>
        <Modal 
            show={showModalError}
            centered
            onHide={()=>setShowModalError(false)}
        >
            <Modal.Header closeButton>{modalErrorTitle.current}</Modal.Header>
            <Modal.Body>{modalErrorMsg.current}</Modal.Body>
        </Modal>
        <Row>
            <Col className="text-center">
                <img src={logo} alt="SANUS Logo" className="login-logo"></img>
            </Col>
        </Row>
        <hr></hr>
        <Row className="justify-content-center mt-5">
            <Col className="mt-5" xs={"auto"}>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="usuario">Usuario</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="email@dominio.com"
                        aria-label="usuario"
                        aria-describedby="usuario"
                        value={usuario}
                        onChange={e=>setUsuario(e.target.value)}
                    />
                </InputGroup>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={"auto"}>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="clave">Clave</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="contraseña"
                        aria-label="clave"
                        type="password"
                        aria-describedby="clave"
                        value={clave}
                        onChange={e=>setClave(e.target.value)}
                    />
                </InputGroup>
            </Col>
        </Row>
        <Row className="mt-3 mb-5 justify-content-center">
            <Col className="mb-5" xs={"auto"}>
                <LoaderButton 
                    block
                    isLoading={isLoading}
                    variant="outline-primary"
                    disabled={!enableLogin()}
                    onClick={()=>handleLogin()}
                > Ingresar</LoaderButton>
            </Col>
        </Row>
        <hr></hr>
        <Row className="login.current-footer">
            <Col className="text-center align-self-center">
                <Form.Text className="text-muted footer-login.current-text">
                    © Grupo Valor International Solutions - Todos los derechos reservados.
                </Form.Text>
            </Col>
        </Row>
    </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);