import React from "react";
import { Row, Col } from "react-bootstrap";
import { AiFillCode } from "react-icons/ai";
import Paper from '@material-ui/core/Paper';

const UnderConstruction = () => {

    return (
        <Paper>
            <Row className="pt-3 pb-5">
                <Col className="text-center">
                    <AiFillCode size={160}/>
                    <h1>En construcción</h1>
                    <h2>Vuelve pronto</h2>
                </Col>
            </Row>
        </Paper>
    )
};

export default UnderConstruction;