const dev = {
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://8bgc3cp1c7.execute-api.us-east-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_YAF5fkQvR",
        APP_CLIENT_ID: "15u55p8re8hlh0bf899f584s89",
        IDENTITY_POOL_ID: "us-east-2:575f7ad5-7dfc-429c-acc1-45fc6215beb0"
    },
    stripe_pk: "pk_test_"
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://zcl1pgm6f7.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_sw52KLgOU",
      APP_CLIENT_ID: "4q58h3l8m1g2aosjchtri0lguh",
      IDENTITY_POOL_ID: "us-east-2:16f0e5c5-26a3-47c5-96f8-fb02b86809b4"
  }
};

// Si no se especifica el ambiente (dev ó prod), se asume que el ambiente es dev
const state = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

const config = {
    // Aca las configuraciones para ambos ambientes
    customUser: "userTest",
    customUserPass: "userTestPass",
    ...state
  };

export default config;