import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Spinner, InputGroup, FormControl, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import {
  ViewState, EditingState, IntegratedEditing,
  IntegratedGrouping, GroupingState
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler as Schedul,
  DayView, WeekView, MonthView, Appointments, Toolbar, TodayButton,
  ViewSwitcher, DateNavigator, AppointmentTooltip, AppointmentForm,
  Resources,
  GroupingPanel
}
  from "@devexpress/dx-react-scheduler-material-ui";
import Paper from '@material-ui/core/Paper';
import {
  amber, blue, blueGrey, brown, common,
  cyan, deepOrange, deepPurple, green, grey,
  indigo, lightBlue, lightGreen, lime, orange,
  pink, purple, red, teal, yellow
}
  from "@material-ui/core/colors";
import LoaderButton from "./loaderButton";
import { FaSearch } from "react-icons/fa";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Chip, Avatar } from "@material-ui/core";

const mapStateToProps = state => ({
  userId: state.userId,
  institucionId: state.institucionId,
  userRol: state.userRol
});

const mapDispatchToProps = dispatch => ({
  showError(error_data) {
    dispatch({
      type: "SHOW_ERROR",
      error_data
    })
  },
});

const Scheduler = ({ userId, institucionId, userRol, showError }) => {

  const currentDate = useRef(new Date());
  const currentMonth = useRef(null);
  const currentYear = useRef(null);
  // const tomorrow = useRef(new Date());
  // tomorrow.current.setDate(tomorrow.current.getDate() + 1)
  const [isLoading, setIsLoading] = useState(true);
  // const [resourcesLoaded, setResourcesLoaded] = useState(false);
  // const [especialistasLoaded, setEspecialistasLoaded] = useState(false);
  // const [fatalError, setFatalError] = useState(false);
  const isPacienteSelected = useRef(false);
  const [isSearching, setIsSearching] = useState(false);
  const [reload, setReload] = useState(true);
  const [scheduleData, setScheduleData] = useState([]);

  const tableData = useRef([]);
  const isFirstRender = useRef(true);
  const bootstrapTableMsg = useRef("No hay pacientes que mostrar");
  const resoursesError = useRef(false);

  const columns = useRef([
    {
      dataField: "paciente",
      text: "Resultados búsqueda",
    }
  ]);

  const resources = useRef([{}]);

  // const resources = useRef([
  //   {
  //     fieldName: 'especialista',
  //     title: 'Especialista',
  //     instances: [
  //       { id: '1', text: 'carlos hernandez', color: amber },
  //       { id: '2', text: 'diana jaramillo', color: blue },
  //       { id: '3', text: 'alberto martinez', color: blueGrey },
  //       { id: '4', text: 'claudia ramos', color: brown },
  //       { id: '5', text: 'andres castillo', color: common },
  //     ],
  //   },
  //   {
  //     fieldName: 'servicio',
  //     title: 'Servicio',
  //     instances: [
  //       { id: "1", text: 'salud ocupacional', color: cyan },
  //       { id: "2", text: 'pruebas covid', color: deepOrange },
  //       { id: "3", text: 'servicio de medico a domicilio', color: deepPurple },
  //       { id: "4", text: 'teleconsulta', color: green },
  //     ]
  //   }
  // ]);

  const isVisibleAppoinmentForm = useRef(false);
  const mainResourceName = useRef('especialista');
  const grouping = useRef([
    {
      resourceName: 'especialista'
    },
    // {
    //   resourceName: 'servicio'
    // }
  ]);

  const messages = {
    moreInformationLabel: 'Detalles',
    notesLabel: 'Notas',
    allDayLabel: 'Todo el día',
    commitCommand: 'Guardar',
    detailsLabel: 'Servicio 1',
    repeatLabel: 'Recurrente',
    today: 'Hoy'
    //titleLabel: 'Titulo'
    //occurrencesLabel: 'Recurrencia'
    //titleLabel: 'Titulo'
  };

  const handleErrors = useCallback((exception) => {
    console.log(exception);
    var msg = '';
    switch (exception) {
      case "DeleteServicioException":
        msg = 'No fue posible eliminar el servicio';
        break;
      case "GetServiciosDataException":
        msg = 'No fue posible recuperar la información de los servicio, no es posible acceder a la funcionalidad de citas';
        break;
      case "GetEspecialistasDataException":
        msg = 'No fue posible recuperar la información de los especialistas, no es posible acceder a la funcionalidad de citas';;
        break;
      case "MissingInstitucionIdException":
        msg = 'Se encontro un error de configuración, por favor cierre e inicie sesión nuevamente';
        break;
      case 'GetUserDataException':
        bootstrapTableMsg.current = 'No es posible obtener la información del paciente, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
        break;
      case 'CreatingCitaException':
        msg = 'No fue posible crear la nueva cita, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
        break;
      case 'GetCitasDataException':
        msg = 'No es posible obtener las citas para el periodo indicado, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';  
        break;
      case 'EditingCitaException':
        msg = 'No fue posible actualizar los cambios de la cita, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
        break;
      case 'DeletingCitaException':
        msg = 'No fue posible eliminar la cita, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
        break;
      case 'NoResourcesFoundException':
        msg = 'Tu institución aún no cuenta con especialistas y/o servicios, no es posible crear nuevas citas o consultar el calendario.';
        break;
      default:
        msg = 'No fue posible realizar la acción, por favor intentelo de nuevo, si el problema persiste, comuniquese con nosotros'
        break;
    }
    const error_data = {
      show: true,
      msg: msg
    };
    showError(error_data)
  }, [showError]);

  const getCitas = useCallback(async()=>{ //Date object
    setIsLoading(true);
    try{
      const unixStartDate = Date.parse((currentDate.current.getMonth()+1)+"/1/"+currentDate.current.getFullYear()+"/00:00:00");
      const unixEndDate = Date.parse((currentDate.current.getMonth()+1)+"/"+new Date((currentDate.current.getFullYear(),(currentDate.current.getMonth()+1),0)).getDate()+"/"+currentDate.current.getFullYear()+"/23:59:00");
      
      const qsp = {
        institucionId: institucionId,
        startDate: unixStartDate,
        endDate: unixEndDate,
        get: ['*']
      };
      // console.log(qsp);
      const citasData = await API.get("sanus","/getCitas",{
        queryStringParameters: qsp
      });
      if(citasData.fail){
        const error = 'GetCitasDataException';
        throw error;
      }
      // console.log(citasData);
      const sD = [];
      for (let index = 0; index < citasData.length; index++) {
        const element = citasData[index];
        var especialistaIndex = '';
        var servicioIndex = '';
        for (let index = 0; index < resources.current.length; index++) {
          const elementB = resources.current[index];
          switch (elementB.fieldName) {
            case 'especialista':
              for (let index = 0; index < elementB.instances.length; index++) {
                const elementC = elementB.instances[index];
                if(elementC.sanusId === element.especialistaData.usuarioId){
                  especialistaIndex = elementC.id;
                  break;
                }
              }
              break;
            case 'servicio':
              for (let index = 0; index < elementB.instances.length; index++) {
                const elementC = elementB.instances[index];
                if(elementC.sanusId === element.servicioData.servicioId){
                  servicioIndex = elementC.id;
                  break;
                }
              }
              break;
            default:
              break;
          }
        }
        const appoData = {
          id: index,
          title: element.servicioData.nombre.charAt(0).toUpperCase() + element.servicioData.nombre.slice(1),
          startDate: new Date(element.startDate),
          endDate: new Date(element.endDate),
          allDay: false,
          paciente: element.paciente,
          notas: element.detalles.charAt(0).toUpperCase() + element.detalles.slice(1),
          citaId: element.citaId,
          especialista: especialistaIndex,
          servicio: servicioIndex
        }
        sD.push(appoData);
      }
      setScheduleData(sD);
      setIsLoading(false);
    } catch(error){
      handleErrors(error);
    }
  },[handleErrors, institucionId]);

  useEffect(() => {
    const colors = [amber, blue, blueGrey, brown, common,
      cyan, deepOrange, deepPurple, green, grey,
      indigo, lightBlue, lightGreen, lime, orange,
      pink, purple, red, teal, yellow]

    const getResources = async () => {
      try {
        const especialistasData = await API.get("sanus", "/getEspecialistas", {
          queryStringParameters: {
            institucionId: institucionId,
            get: ['usuarioId', 'nombre', 'apellido', 'estado']
          }
        });
        if (especialistasData.fail) {
          console.log(especialistasData);
          const error = 'GetEspecialistasDataException'
          throw error;
        }
        if(especialistasData.length === 0){
          resoursesError.current = true;
          const error = 'NoResourcesFoundException';
          throw error;
        }
        const instances = [];
        var colIndex = 0;
        for (let index = 0; index < especialistasData.length; index++) {
          const element = especialistasData[index];
          if (element.estado) {
            const especData = {
              id: String(index + 1),
              sanusId: element.usuarioId,
              text: element.nombre.charAt(0).toUpperCase() + element.nombre.slice(1) + ' ' + element.apellido.charAt(0).toUpperCase() + element.apellido.slice(1),
              color: index < colors.length ? colors[index] : colors[colIndex]
            };
            instances.push(especData);
          }
          if (!index < colors.length) {
            if (colIndex > colors.length) {
              colIndex = 0;
            } else {
              colIndex = colIndex = + 1
            }
          }
        }
        const especialistas = {
          fieldName: 'especialista',
          title: 'Especialista',
          instances: instances
        };
        resources.current.push(especialistas);

        const servicesData = await API.get("sanus", "/getServicios", {
          queryStringParameters: {
            institucionId: institucionId,
            get: ['servicioId', 'nombre', 'estado'],
          }
        });
        if (servicesData.fail) {
          console.log(servicesData);
          const error = 'GetServiciosDataException';
          throw error;
        }
        if(servicesData.length === 0){
          resoursesError.current = true;
          const error = 'NoResourcesFoundException';
          throw error;
        }

        const instancess = [];
        var colIndex2 = 0;
        for (let index = 0; index < servicesData.length; index++) {
          const element = servicesData[index];
          if (element.estado) {
            const servicesData = {
              id: String(index + 1),
              sanusId: element.servicioId,
              text: element.nombre.charAt(0).toUpperCase() + element.nombre.slice(1),
              color: index < colors.length ? colors[index] : colors[colIndex2]
            };
            instancess.push(servicesData);
          }
          if (!index < colors.length) {
            if (colIndex2 > colors.length) {
              colIndex2 = 0;
            } else {
              colIndex2 = colIndex2 = + 1
            }
          }
        }
        const services = {
          fieldName: 'servicio',
          title: 'Servicio',
          instances: instancess
        };
        resources.current.push(services);
        await getCitas();
        setIsLoading(false);
      } catch (error) {
        handleErrors(error);
      }
    };

    if (isFirstRender.current) {
      isFirstRender.current = false;
      resources.current = [];
      currentMonth.current = currentDate.current.getMonth() + 1;
      currentYear.current = currentDate.current.getFullYear();
      getResources();
    }
  }, [handleErrors, institucionId, getCitas]);

  const LabelComponent = (props) => {
    // if(props.text==="Especialista" || props.text==="Servicio"){
    //   return <AppointmentForm.Label {...props} />
    // } else {
    //   return null;
    // }
    return null;
  }

  const DateEditor = (props) => {
    //console.log(props);
    //return <AppointmentForm.DateEditor {...props} />;
    return null
  }

  const TextEditor = (props) => {
    //console.log(props);
    // eslint-disable-next-line react/destructuring-assignment
    // if (props.type === 'multilineTextEditorabc') {
    //   return null;
    // } return <AppointmentForm.TextEditor {...props} />;
    return null;
  };

  const resourceEditor = (props) => {
    //console.log(props);
    return null;
  }

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    
    const handleSearchUser = async () => {
      setIsSearching(true);
      tableData.current = [];
      const qsp = {
        institucionId: institucionId,
        get: ['usuarioId', 'identificacion', 'nombre', 'apellido', 'email', 'estado'],
        searchRol: 'paciente',
        searchCriteria: appointmentData.searchCriteria.trim().toLowerCase()
      }
      try {
        const searchData = await API.get("sanus", "/getUsers", {
          queryStringParameters: qsp
        });
        if (searchData.fail) {
          console.log(searchData.description);
          const error = 'GetUserDataException';
          throw error;
        }
        for (let index = 0; index < searchData.length; index++) {
          const element = searchData[index];
          if (element.estado) {
            var pacData = element;
            pacData.paciente = [element.nombre.charAt(0).toUpperCase() + element.nombre.slice(1) + " " + element.apellido.charAt(0).toUpperCase() + element.apellido.slice(1), <Form.Text key='00x' className="text-muted">
              {element.identificacion} | {element.email}</Form.Text>]
            tableData.current.push(pacData);
          }
        }
        // console.log(tableData.current);
      } catch (error) {
        handleErrors(error);
      }
      setIsSearching(false);
    }

    const handleQuitPaciente = () => {
      onFieldChange({ paciente: undefined });
      tableData.current = [];      
    };

    const handleOnSelect = (row, isSelect) => {
      setReload(false);
      var pacienteData = null;
      if (isSelect) {
        isPacienteSelected.current = true;
        pacienteData = row;
      } else {
        isPacienteSelected.current = false;
        pacienteData = null;
      }
      onFieldChange({ paciente: pacienteData });
      setReload(true);
    };

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: handleOnSelect
    };

    const handleEnableSearchBtn = () => {
      return appointmentData.searchCriteria.length > 3
    };

    const onPacienteChange = (nextValue) => {
      onFieldChange({ searchCriteria: nextValue });
    };

    const onStartDateChange = (nextValue) => {
      onFieldChange({ startDate: nextValue });
    };

    const onEndDateChange = (nextValue) => {
      onFieldChange({ endDate: nextValue });
    };

    const onNotasChange = (nextValue) => {
      onFieldChange({ notas: nextValue });
    };

    const onEspecialistaChange = (nextValue) => {
      onFieldChange({ especialista: nextValue['especialista'] });
    };

    const onServicioChange = (nextValue) => {
      var nombreServicio = '';
      for (let index = 0; index < restProps.resources.length; index++) {
        const element = restProps.resources[index];
        if (element.fieldName === 'servicio') {
          const instances = element.instances;
          for (let inxB = 0; inxB < instances.length; inxB++) {
            const element2 = instances[inxB];
            if (element2.id === nextValue['servicio']) {
              nombreServicio = element2.text;
            }
          }
        }
      }
      onFieldChange({ servicio: nextValue['servicio'], title: nombreServicio });
    };

    //console.log(appointmentData);
    //console.log(restProps);
    //console.log(resources.current);
    if (appointmentData.title) {
      if (!appointmentData.servicio) {
        appointmentData.servicio = resources.current[1].instances[0].id;
      }
      if (!appointmentData.especialista) {
        appointmentData.especialista = resources.current[0].instances[0].id;
      }
    } else {
      if (!appointmentData.servicio) {
        appointmentData.servicio = resources.current[1].instances[0].id;
      }
      if (!appointmentData.especialista) {
        appointmentData.especialista = resources.current[0].instances[0].id;
      }
    }
    if (!appointmentData.searchCriteria) {
      appointmentData.searchCriteria = '';
    }
    if(isVisibleAppoinmentForm.current){
      if(appointmentData.paciente){
        isPacienteSelected.current = true;
      } else {
        isPacienteSelected.current = false;
      }
    }
    return (
      <AppointmentForm.BasicLayout
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
        {...restProps}
      >
        <Row >
          <Col>
            <AppointmentForm.Label
              text="Especialista"
              type="title"
            />
          </Col>
        </Row>
        <AppointmentForm.ResourceEditor
          resource={restProps.resources[0]}
          onResourceChange={onEspecialistaChange}
          value={appointmentData.especialista}
        />
        <Row className="mt-2">
          <Col>
            <AppointmentForm.Label
              text="Servicio"
              type="title"
            />
          </Col>
        </Row>
        <AppointmentForm.ResourceEditor
          resource={restProps.resources[1]}
          onResourceChange={onServicioChange}
          value={appointmentData.servicio}
          //value={"2"}
        />
        <Row className="mt-2">
          <Col>
            <AppointmentForm.Label
              text="Paciente"
              type="title"
            />
          </Col>
        </Row>
        {reload && (
          isPacienteSelected.current ? 
            <Row className="mt-2">
              <Col>
                <Chip
                  avatar={<Avatar>{appointmentData.paciente.nombre.charAt(0).toUpperCase()}</Avatar>}
                  //icon={<FaCommentMedical size={20}/>}
                  label={appointmentData.paciente.nombre.charAt(0).toUpperCase() + appointmentData.paciente.nombre.slice(1) + " " +
                        appointmentData.paciente.apellido.charAt(0).toUpperCase() + appointmentData.paciente.apellido.slice(1)}
                  //onClick={()=>handleQuitServicio(key)}
                  onDelete={()=>handleQuitPaciente()}
                  color="primary"
                  variant="outlined"
                />
              </Col>
            </Row>
          :
            <div>
              <Row >
                <Col>
                  <InputGroup >
                    <FormControl
                      placeholder="Nombre, apellido o ID del paciente"
                      aria-label="criteria"
                      aria-describedby="criteria"
                      value={appointmentData.paciente}
                      onChange={e => onPacienteChange(e.target.value)}
                    />
                    <InputGroup.Append>
                      <LoaderButton
                        isLoading={isSearching}
                        variant="outline-primary"
                        disabled={!handleEnableSearchBtn()}
                        onClick={() => handleSearchUser()}
                      > <FaSearch /></LoaderButton>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              {isSearching ?
                <Row>
                  <Col className="text-center mt-3">
                    <Spinner animation="border" variant="primary" />
                  </Col>
                </Row>
              :
                <Row className="mt-1">
                  <Col>
                    <BootstrapTable
                      keyField="usuarioId"
                      data={tableData.current}
                      columns={columns.current}
                      pagination={paginationFactory()}
                      noDataIndication={bootstrapTableMsg.current}
                      selectRow={selectRow}
                    />
                  </Col>
                </Row>
              }
            </div>
          )
        }
        {/* <AppointmentForm.TextEditor
          type="inputTextEditor"
          placeholder="DNI Paciente"
          value={appointmentData.paciente}
          onValueChange={onPacienteChange}
        /> */}
        <Row className="justify-content-center align-items-center">
          <Col xs={"auto"}>
            <AppointmentForm.DateEditor
              value={appointmentData.startDate}
              onValueChange={onStartDateChange}
            />
          </Col>
          <Col xs={"12"} md={"auto"} className="text-center">
            <AppointmentForm.Label
              text=" - "
            />
          </Col>
          <Col xs={"auto"}>
            <AppointmentForm.DateEditor
              value={appointmentData.endDate}
              onValueChange={onEndDateChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AppointmentForm.Label
              text="Detalles"
              type="title"
            />
          </Col>
        </Row>
        <AppointmentForm.TextEditor
          type="multilineTextEditor"
          placeholder="Notas"
          value={appointmentData.notas}
          onValueChange={onNotasChange}
        />
      </AppointmentForm.BasicLayout>
    );
  };

  const handleCommitChanges = async(event) => {
    setIsLoading(true);
    //console.log(event);
    if (event.added) {
      const data = [...scheduleData];
      const element = event.added
      element.id = data.length
      if (!element.servicio) {
        element.servicio = '1';
      }
      if (!element.especialista) {
        element.especialista = '1';
      }
      for (const index in resources.current) {
        const element2 = resources.current[index];
        if (element2.fieldName === "servicio") {
          const instances = element2.instances;
          for (let indexB = 0; indexB < instances.length; indexB++) {
            const instData = instances[indexB];
            if (instData.id === element.servicio) {
              element.title = instData.text;
            }
          }

        }
      }
      var especData = '';
      var servData = '';
      for (let index = 0; index < resources.current.length; index++) {
        const elementB = resources.current[index];
        switch (elementB.fieldName) {
          case 'especialista':
            for (let index = 0; index < elementB.instances.length; index++) {
              const elementC = elementB.instances[index];
              if(elementC.id === String(element.especialista)){
                especData = elementC;
              }
            }
            break;
          case 'servicio':
            for (let index = 0; index < elementB.instances.length; index++) {
              const elementC = elementB.instances[index];
              if(elementC.id === String(element.servicio)){
                servData = elementC;
              }
            }
            break;
          default:
            break;
        }
      }
      const appoinment = {
        institucionId: institucionId,
        paciente: element.paciente,
        servicioData: {
          servicioId: servData.sanusId,
          nombre: servData.text.trim().toLowerCase()
        },
        startDate: element.startDate,
        endDate: element.endDate,
        especialistaData: {
          usuarioId: especData.sanusId,
          nombre: especData.text.trim().toLowerCase()
        },
        detalles: (element.notas ? element.notas.trim().toLowerCase() : ''),
      };
      try{
        const respuesta = await API.post("sanus","/crearCita",{
          body: appoinment
        });
        if(respuesta.fail){
          console.log(respuesta);
          const error = 'CreatingCitaException';
          throw error;
        }
        element.citaId = respuesta.description;
        data.push(element);
        setScheduleData(data);
        setIsLoading(false);
      } catch (error){
        handleErrors(error);
        setIsLoading(false);
      }
    }

    if (event.changed) {
      const data = [...scheduleData];
      //console.log(event);
      const element = event.changed;
      // console.log(element);
      const appDataChanges = [];
      var citaId = '';
      for (const i in element) {
        for (let index = 0; index < data.length; index++) {
          const element2 = data[index];
          if (element2.id === parseInt(i)) {
            const element3 = element[i]
            citaId = element2.citaId;
            //console.log(citaId);
            for (const k in element3) {
              //console.log(k);
              const appData = {
                propiedad: k,
                value: element3[k]
              }
              appDataChanges.push(appData);
              element2[k] = element3[k];
            }
            data[index] = element2;
          }
        }
      }
      const appDataChangesToSave = [];
      for (let index = 0; index < appDataChanges.length; index++) {
        const element = appDataChanges[index];
        switch (element.propiedad) {
          case 'servicio':
            for (let indexB = 0; indexB < resources.current.length; indexB++) {
              const elementB = resources.current[indexB];
              if(elementB.fieldName === 'servicio'){
                const servData = {
                  servicioId: elementB.instances[element.value - 1].sanusId,
                  nombre: elementB.instances[element.value - 1].text.trim().toLowerCase(),
                }
                appDataChangesToSave.push({
                  propiedad: 'servicioData',
                  value: servData
                });
              }
            }
            break;
          case 'especialista':
            for (let indexB = 0; indexB < resources.current.length; indexB++) {
              const elementB = resources.current[indexB];
              if(elementB.fieldName === 'especialista'){
                const especData = {
                  usuarioId: elementB.instances[element.value - 1].sanusId,
                  nombre: elementB.instances[element.value - 1].text.trim().toLowerCase(),
                }
                appDataChangesToSave.push({
                  propiedad: 'especialistaData',
                  value: especData
                });
              }
            }
            break;
          case 'notas':
            appDataChangesToSave.push({
              propiedad: 'detalles',
              value: element.value.trim().toLowerCase()
            });
            break;
          default:
            if(element.propiedad !== 'title' && element.propiedad !== 'searchCriteria'){
              appDataChangesToSave.push(element);
            }
            break;
        }
      }
      // console.log(appDataChanges);
      // console.log(appDataChangesToSave);
      try {
        const respuesta = await API.put("sanus","/citas/"+citaId,{
          body: {
            propiedades: appDataChangesToSave
          }
        });
        if(respuesta.fail){
          console.log(respuesta);
          const error = 'EditingCitaException';
          throw error;
        }
        setScheduleData(data);
        setIsLoading(false);
      } catch (error) {
        handleErrors(error);
        setIsLoading(false);
      }
    }

    if (event.deleted) {
      const data = [...scheduleData];
      const iDel = event.deleted;
      var cita_Id = '';
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.id === iDel) {
          cita_Id = element.citaId;
          data.splice(index, 1);
        }
      }
      try {
        const respuesta = await API.del("sanus","/citas/"+cita_Id);
        if(respuesta.fail){
          console.log(respuesta);
          const error = 'DeletingCitaException';
          throw error;
        }
        setScheduleData(data);
        setIsLoading(false);
      } catch (error) {
        handleErrors(error);
        setIsLoading(false);
      }
    }
  };

  const BooleanEditor = (props) => {
    return null
  };

  const OverlayComponent = (props) => {
    isVisibleAppoinmentForm.current = props.visible;
    // if(isOverlayFirstRender.current){
    //   isOverlayFirstRender.current = false;
    // } else {
    //   if(!isVisibleAppoinmentForm.current){
    //     setIsPacienteSelected(false);
    //   }
    // }
    // console.log(isVisibleAppoinmentForm.current);
    return <AppointmentForm.Overlay {...props} />
  }

  const handleDateChange = (newDate) => {
    // console.log(newDate);
    const newMonth = newDate.getMonth() + 1;
    const newYear = newDate.getFullYear();
    if(newMonth !== currentMonth.current || newYear !== currentYear.current){
      currentMonth.current = newMonth;
      currentYear.current = newYear;
      currentDate.current = newDate;
      getCitas();
    }
  }

  return (
    <div>
      {resoursesError.current ?
        <></> :
        isLoading ?
        <Row>
          <Col className="text-center mt-3">
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
        :
        <Paper className="mb-2">
          <Schedul 
            data={scheduleData} 
            locale={"es-CO"} 
          >
            <ViewState
              defaultCurrentDate={currentDate.current}
              defaultCurrentViewName="semana"
              onCurrentDateChange={handleDateChange}
            />
            {(userRol !== 'especialista' && userRol !== 'paciente') ? 
              <EditingState
                onCommitChanges={(event) => handleCommitChanges(event)}
              />
            :
              <></>
            }
            {(userRol !== 'especialista' && userRol !== 'paciente') ? 
              <GroupingState
                grouping={grouping.current}
              />
            : 
              <></>
            }
            {/* <GroupingState
              grouping={grouping.current}
            /> */}
            <DayView
              name="dia"
              displayName="dia"
              startDayHour={9}
              endDayHour={18}
            />
            <WeekView
              name="semana"
              displayName="Semana"
              startDayHour={9}
              endDayHour={18}
            />
            <MonthView
              name="mes"
              displayName="Mes"
            />
            {/* <ConfirmationDialog /> */}
            <Toolbar />
            <DateNavigator />
            <TodayButton messages={messages} />
            <ViewSwitcher />
            <Appointments />
            <Resources
              data={resources.current}
              mainResourceName={mainResourceName.current}
            />
            {(userRol !== 'especialista' && userRol !== 'paciente') ? <IntegratedGrouping /> : <></>}
            {(userRol !== 'especialista' && userRol !== 'paciente') ? <IntegratedEditing /> : <></>}
            {/* <IntegratedGrouping />
            <IntegratedEditing /> */}
            <AppointmentTooltip
              showCloseButton
              showOpenButton
              showDeleteButton
            />
            <AppointmentForm
              basicLayoutComponent={BasicLayout}
              textEditorComponent={TextEditor}
              dateEditorComponent={DateEditor}
              resourceEditorComponent={resourceEditor}
              labelComponent={LabelComponent}
              messages={messages}
              booleanEditorComponent={BooleanEditor}
              overlayComponent={OverlayComponent}
              readOnly={userRol === 'especialista' || userRol === 'paciente'}
            />
            {(userRol !== 'especialista' && userRol !== 'paciente') ? <GroupingPanel /> : <></>}
          </Schedul>
        </Paper>
      }
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);