import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Spinner, Modal, Button, 
    InputGroup, FormControl, Accordion, Card, Alert } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { BiMessageSquareError } from 'react-icons/bi';
import { IoMdAdd } from 'react-icons/io';
import LoaderButton from './loaderButton';
import { useFormFields } from '../libs/hooksLib';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditorEntradaHistoriaBlock from './editorEntradaHistoriaBlock';

const mapStateToProps = state => ({
    institucionId: state.institucionId,
    usuarioSelectedId: state.usuarioSelectedId,
    userRol: state.userRol,
});

const mapDispatchToProps = dispatch => ({
    showError(error_data){
        dispatch({
            type: "SHOW_ERROR",
            error_data
        })
    },
    showHistoriaClinica(usuario_selected_id){
        dispatch({
            type: "SET_USUARIO_SELECTED_ID",
            usuario_selected_id
        })
    },
});

const Historia = ({institucionId, usuarioSelectedId, userRol, showError, showHistoriaClinica}) => {

    const initialData = {
        usuarioId: usuarioSelectedId,
        historiaId: "",
        institucionId: institucionId,
        identificacion: "",
        nombre: "",
        apellido: "",
        edad: "",
        sexo: '',
        ocupacion: '',
        nacimiento: new Date(),
        estado_civil: '',
        nacionalidad: '',
        direccion: '',
        educacion: '',
        entradas: []
    };

    const educacion = ['No informa','primaria','secundaria','tecnico','universitario','superior'];

    const [isLoading, setIsLoading] = useState(true);
    const [showModalHistoriaClinica, setShowModalHistoriaClinica] = useState(false);
    const [anamnesis, setAnamnesis] = useFormFields(initialData);
    const [activeTab, setActiveTab] = useState();
    const [isLoadingTab, setIsLoadingTab] = useState(true);

    const isFirstRender = useRef(true);
    const msgModalHistoria = useRef('');
    const entradaError = useRef(null);
    const entradasHistoria = useRef([]);
    const toAnamnesis = useRef({});
    const toActiveTab = useRef(null);

    const handleErrors = useCallback((exception)=>{
        var msg = '';
        switch (exception) {
            case 'GetHistoriaException':
                msg = 'No fue posible recuperar la historia clinica del paciente, intentelo de nuevo, si el problema persiste comuniquese con nosotros';
                break;
            case 'GetPacienteDataException':
                msg = 'No fue posible recuperar la información del paciente, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
                break;
            case 'CreateHistoriaException':
                msg = 'No fue posible crear la historia clinica, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
                break;
            case 'SaveAnamnesisChangeException':
                msg = 'Ocurrio un error guardando los cambios, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
                break;
            default:
                msg = 'No fue posible realizar la acción, por favor intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
                break;
        }
        const error_data= {
            show: true,
            msg: msg
        };
        showError(error_data);
    },[showError]);

    useEffect(()=>{
        const getHistoriaClinicia = async() => {
            try {
                const historia = await API.get("sanus","/getHistoria",{
                    queryStringParameters:{
                        institucionId: institucionId,
                        usuarioId: usuarioSelectedId
                    }
                });
                if(historia.fail){
                    console.log(historia);
                    const error = 'GetHistoriaException'
                    throw error;
                }
                if(historia.length === 0){
                    if(userRol !== 'participante'){
                        msgModalHistoria.current = "Este paciente no cuenta con historia clínica en SANUS, ¿desea crear una nueva historia clínica para el paciente?";
                    } else {
                        msgModalHistoria.current = "No se encontro historia clínica para el paciente";
                    }
                    setShowModalHistoriaClinica(true);
                } else {
                    //llenar anamnesis
                    for (let index = 0; index < historia.length; index++) {
                        const element = historia[index];
                        toAnamnesis.current = {
                            usuarioId: element.anamnesis.usuarioId,
                            historiaId: element.historiaId,
                            institucionId: institucionId,
                            identificacion: element.anamnesis.identificacion,
                            nombre: element.anamnesis.nombre,
                            apellido: element.anamnesis.apellido,
                            edad: element.anamnesis.edad,
                            sexo: element.anamnesis.sexo,
                            ocupacion: element.anamnesis.ocupacion,
                            nacimiento: new Date(element.anamnesis.nacimiento),
                            estado_civil: element.anamnesis.estado_civil,
                            nacionalidad: element.anamnesis.nacionalidad,
                            direccion: element.anamnesis.direccion,
                            educacion: element.anamnesis.educacion,
                            entradas: element.entradas
                        }
                    }
                    const currentFecha = new Date();
                    const unixtimestamp = Date.parse((currentFecha.getMonth()+1)+"/"+currentFecha.getDate()+"/"+currentFecha.getFullYear());
                    if(!toAnamnesis.current.entradas.includes(String(unixtimestamp))){
                        toAnamnesis.current.entradas.push(String(unixtimestamp));
                    }
                    toActiveTab.current = toAnamnesis.current.entradas[toAnamnesis.current.entradas.length - 1];
                    setActiveTab(toActiveTab.current);
                    setAnamnesis({target:{value: toAnamnesis.current, type:"cleanUp"}});
                    getEntrada();
                    setIsLoading(false);
                }
            } catch (error) {
                handleErrors(error)
                setIsLoading(false);
                showHistoriaClinica(null);
            }
        };

        if(isFirstRender.current){
            isFirstRender.current = false;
            getHistoriaClinicia();
        }
    },[institucionId, handleErrors, userRol, usuarioSelectedId, showHistoriaClinica, setAnamnesis]);
    
    useEffect(()=>{
        return function cleanUp(){
            showHistoriaClinica(null);
        }
    },[showHistoriaClinica]);

    const loadPersonalData = useCallback(async(pacienteData) => {
        var tempAnamnesis = anamnesis;
        const fecha = new Date(pacienteData.nacimiento);
        const currentFecha = new Date();
        const year = fecha.getFullYear();
        const month = ((fecha.getMonth()+1) < 10 ? '0'+(fecha.getMonth()+1):fecha.getMonth()+1);
        const date = (fecha.getDate() < 10 ? '0'+fecha.getDate():fecha.getDate());
        const historiaId = String(year)+String(month)+String(date)+pacienteData.identificacion;
        const unixtimestamp = Date.parse((currentFecha.getMonth()+1)+"/"+currentFecha.getDate()+"/"+currentFecha.getFullYear());
        if(!tempAnamnesis.entradas.includes(unixtimestamp)){
            tempAnamnesis.entradas.push(unixtimestamp);
        }
        setActiveTab(tempAnamnesis.entradas[tempAnamnesis.entradas.length - 1]);
        tempAnamnesis.historiaId = historiaId;
        tempAnamnesis.edad = (currentFecha.getFullYear()) - year;
        for(const k in pacienteData){
            tempAnamnesis[k] = pacienteData[k];                
        }
        toAnamnesis.current = tempAnamnesis;
        setAnamnesis({target:{value: toAnamnesis.current, type:"cleanUp"}});
        getEntrada();
    },[anamnesis, setAnamnesis]);

    const handleCrearNuevaHistoria = async() => {
        setShowModalHistoriaClinica(false);
        try{
            const pacienteData = await API.get("sanus","/usuarios/"+usuarioSelectedId,{
                queryStringParameters: {
                    get: ['nombre','apellido',
                    'identificacion','nacimiento'],
                }
            });
            if(pacienteData.fail){
                console.log(pacienteData)
                const error = 'GetPacienteDataException'
                throw error;
            }
            await loadPersonalData(pacienteData);
            const nuevaHistoria = API.post('sanus','/crearHistoria',{
                body: anamnesis
            });
            if(nuevaHistoria.fail){
                console.log(nuevaHistoria);
                const error = 'CreateHistoriaException';
                throw error;
            }
        } catch(error){
            console.log(error);
            handleErrors(error)
            showHistoriaClinica(null);
        };
        setIsLoading(false);
    };

    const handleCloseModal = () => {
        setShowModalHistoriaClinica(false);
        showHistoriaClinica(null);
    };

    const getEntrada = async() => {
        setIsLoadingTab(true);
        entradasHistoria.current = [];
        entradaError.current = null;
        console.log("cargando entrada");
        try {
            const entradas = await API.get("sanus","/entradas/"+toActiveTab.current,{
                queryStringParameters: {
                    historiaId: toAnamnesis.current.historiaId
                }
            });
            if(entradas.fail){
                console.log(entradas);
                const error = 'No se puede cargar esta entrada de la historia, intentelo de nuevo, si el problema persiste comuniquese con nosotros';
                throw error;
            }
            entradasHistoria.current = entradas;
            setIsLoadingTab(false);
        } catch (error) {
            entradaError.current = error;
            setIsLoadingTab(false);
        }
    };
    
    const RenderEntrada = () => {
        const currentDate = new Date();
        const unixCurrentDate = Date.parse((currentDate.getMonth()+1)+"/"+currentDate.getDate()+"/"+currentDate.getFullYear());
        const isSameDay = unixCurrentDate === parseInt(activeTab);
        const dia = (currentDate.getDate() < 10 ? '0'+currentDate.getDate() : currentDate.getDate());
        const mes = ((currentDate.getMonth()+1) < 10 ? '0'+(currentDate.getMonth()+1) : currentDate.getMonth()+1);
        const anio = currentDate.getFullYear();
        const hora = (currentDate.getHours() < 10 ? '0'+currentDate.getHours() : currentDate.getHours());
        const minuto = (currentDate.getMinutes() < 10 ? '0'+currentDate.getMinutes() : currentDate.getMinutes());
        const segundos = (currentDate.getSeconds() < 10 ? '0'+currentDate.getSeconds() : currentDate.getSeconds());

        var editorId = null;

        isSameDay ? 
            editorId = String(hora)+':'+String(minuto)+':'+String(segundos)
            : editorId = String(dia)+"/"+String(mes)+"/"+String(anio)+"T"+String(hora)+":"+String(minuto)+':'+String(segundos);
        
        const blankEditor = {
            historiaId: toAnamnesis.current.historiaId,
            entradaId: toActiveTab.current,
            id: editorId,
            readOnly: false,
        };

        if(entradaError.current){
            return (
                <Alert variant={'warning'} >
                    {entradaError.current}
                </Alert>
            )
        } else {
            return(
                <div>
                    {/* Boton para adicionar editores a la entrada */}
                    <Row className="mb-2">
                        <Col className="text-right">
                            <Button 
                                title='Adicionar un nuevo bloque de texto'
                                variant='outline-primary'
                                onClick={()=>getEntrada()}
                            >
                            <IoMdAdd /></Button>
                        </Col>
                    </Row>
                    {/* regresa un Editor por cada entrada EditorEntradaHistoriaBlock */}
                    <EditorEntradaHistoriaBlock {...blankEditor} />
                    {entradasHistoria.current.length > 0 ?
                        entradasHistoria.current.slice(0).reverse().map(k=>{
                            const editorProps = {
                                historiaId: toAnamnesis.current.historiaId,
                                entradaId: toActiveTab.current,
                                id: k.editorId,
                                content: k.raw_data,
                                readOnly: true,
                            }
                            return (
                                <EditorEntradaHistoriaBlock key={k.editorId} {...editorProps}/>
                            )
                        })
                    :
                        <></>
                    }
                </div>
            )
        }
    };

    const handleActiveTab = (k) => {
        toActiveTab.current = k;
        setActiveTab(k);
        getEntrada();
    };

    const handleSaveAnamnesisChange = async(event) => {
        var update = false;
        if(event.id === 'nacimiento'){
            if(event.value !== toAnamnesis.current[event.id]){
                update = true;
            }
        } else {
            if(anamnesis[event.id] !== toAnamnesis.current[event.id]){
                update = true;
            }
        }
        if(update){
            try {
                const result = await API.put('sanus','/historias/'+anamnesis.historiaId,{
                    body: {
                        propiedades: [
                            {
                                propiedad: event.id, 
                                value: event.value
                            }
                        ]
                    }
                });
                if(result.fail){
                    console.log(result);
                    const error = 'SaveAnamnesisChangeException';
                    throw error;
                } else {
                    toAnamnesis.current[event.id] = event.value;
                    console.log("cambios guardados");
                }
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    return (
        <div>
            <Modal 
                show = {showModalHistoriaClinica}
                onHide = {()=>handleCloseModal()}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <BiMessageSquareError size={30}/>
                </Modal.Header>
                <Modal.Body>
                    {msgModalHistoria.current}
                </Modal.Body>
                {userRol !== 'paciente' && 
                <Modal.Footer>
                    <Button 
                        variant='outline-secondary'
                        disabled={false}
                        onClick={()=>handleCloseModal()}
                    >No, regresar</Button>
                    <LoaderButton 
                        isLoading={false}
                        variant="outline-primary"
                        //disabled={!handleEnableSearchBtn()}
                        onClick={()=>handleCrearNuevaHistoria()}
                    > Si, crear nueva historia</LoaderButton>
                </Modal.Footer>
                }
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-3">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row className="mt-3">
                        <Col sm={'auto'}>
                            <h4>Anamnesis</h4>
                        </Col>
                        <Col className='text-right'>
                            <h4>Historia No. {anamnesis.historiaId}</h4>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col>
                            <h5>Datos personales</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Nombres</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    className='capitalize'
                                    placeholder="Nombre(s) del paciente"
                                    aria-label="nombre"
                                    aria-describedby="nombre"
                                    id="nombre"
                                    value={anamnesis.nombre}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Apellidos</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    className='capitalize'
                                    placeholder="Apellido(s) del paciente"
                                    aria-label="apellido"
                                    aria-describedby="apellido"
                                    id="apellido"
                                    value={anamnesis.apellido}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Identificacion</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="DNI, CC, Pasaporte del paciente"
                                    aria-label="identificacion"
                                    readOnly
                                    aria-describedby="identificacion"
                                    id="identificacion"
                                    value={anamnesis.identificacion}
                                    onChange={setAnamnesis}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Ocupacion</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Situación laboral del paciente"
                                    aria-label="ocupacion"
                                    aria-describedby="ocupacion"
                                    id="ocupacion"
                                    value={anamnesis.ocupacion}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Edad</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Edad del paciente"
                                    aria-label="edad"
                                    type='number'
                                    aria-describedby="edad"
                                    id="edad"
                                    value={anamnesis.edad}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text >Nacimiento</InputGroup.Text>
                                </InputGroup.Prepend>
                                <DatePicker
                                    className="form-control"
                                    id="nacimiento"
                                    selected={anamnesis.nacimiento}
                                    value={anamnesis.nacimiento}
                                    onChange={(e)=>{
                                        const event = {
                                            target: {
                                                id:"nacimiento", 
                                                value: e
                                            }
                                        };
                                        handleSaveAnamnesisChange(event.target);
                                        setAnamnesis(event);
                                    }}
                                    dateFormat='dd/MM/yyyy'
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode='select'
                                    fixedHeight
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Sexo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Sexo del paciente"
                                    aria-label="sexo"
                                    aria-describedby="sexo"
                                    id="sexo"
                                    value={anamnesis.sexo}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Estado civil</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Estado civil del paciente"
                                    aria-label="estado_civil"
                                    aria-describedby="estado_civil"
                                    id="estado_civil"
                                    value={anamnesis.estado_civil}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Nacionalidad</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Nacionalidad del paciente"
                                    aria-label="nacionalidad"
                                    aria-describedby="nacionalidad"
                                    id="nacionalidad"
                                    value={anamnesis.nacionalidad}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Educación</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    as='select'
                                    placeholder="Nivel educativo del paciente"
                                    aria-label="educacion"
                                    aria-describedby="educacion"
                                    id="educacion"
                                    value={anamnesis.educacion}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                >
                                {educacion.map((k)=>{
                                    return (<option key={k}>{k}</option>)
                                })}
                                </FormControl>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Dirección</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Dirección de residencia del paciente"
                                    aria-label="direccion"
                                    aria-describedby="direccion"
                                    id="direccion"
                                    value={anamnesis.direccion}
                                    onChange={setAnamnesis}
                                    onBlur={e=>handleSaveAnamnesisChange(e.target)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row className="mb-3">
                        <Col>
                            <Accordion 
                                defaultActiveKey={anamnesis.entradas[anamnesis.entradas.length - 1]}
                                activeKey={activeTab}
                                onSelect={(k)=>handleActiveTab(k)}
                            >
                                {anamnesis.entradas.slice(0).reverse().map((k)=>{
                                    
                                    return(
                                        <Card key={k}>
                                            <Card.Header>
                                                <Accordion.Toggle 
                                                    as={Button} 
                                                    variant="link" 
                                                    eventKey={k}
                                                >
                                                    {new Date(parseInt(k)).toLocaleDateString()}
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={k}>
                                                {activeTab === k ? 
                                                    isLoadingTab ? 
                                                        <Card.Body>
                                                            <Col className="text-center mt-3">
                                                                <Spinner animation="border" variant="primary" />
                                                            </Col>
                                                        </Card.Body>
                                                    :
                                                        <Card.Body><RenderEntrada /></Card.Body> 
                                                : <></>}
                                            </Accordion.Collapse>
                                        </Card>
                                    )
                                })}
                            </Accordion>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Historia);