import React, { useState, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FaLock, FaLockOpen } from "react-icons/fa";
import { API } from 'aws-amplify';
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => ({
    props: props,
});

const mapDispatchToProps = dispatch => ({
    showError(error_data){
        dispatch({
            type: "SHOW_ERROR",
            error_data
        })
    },
});

/**Renderiza un rich-editor 
 * @param {JSON} props: {
 *      @param {String} id: id del editor,
 *      @param {Boolean} readOnly: iniciar el editor en modo solo lectura o no
 *      @param {Raw} content: contenido del editor a cargar
 * }
*/
const EditorEntradaHistoriaBlock = ({props, showError}) => {
    
    const [editorState,setEditorState]  = useState(props.content ? 
        EditorState.createWithContent(convertFromRaw(props.content)) 
        : EditorState.createEmpty());
    const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false);
    
    const currentDate = useRef(new Date());
    const previousState = useRef(editorState);
    
    const handleEditorStateChange = (eState) => {
        setEditorState(eState);
    };

    const handleEditorId = () => {
        const dia = (currentDate.current.getDate() < 10 ? '0'+currentDate.current.getDate() : currentDate.current.getDate());
        const mes = ((currentDate.current.getMonth()+1) < 10 ? '0'+(currentDate.current.getMonth()+1) : currentDate.current.getMonth()+1);
        const anio = currentDate.current.getFullYear();
        const hora = (currentDate.current.getHours() < 10 ? '0'+currentDate.current.getHours() : currentDate.current.getHours());
        const minuto = (currentDate.current.getMinutes() < 10 ? '0'+currentDate.current.getMinutes() : currentDate.current.getMinutes());
        return (String(dia)+"/"+String(mes)+"/"+String(anio)+"T"+String(hora)+":"+String(minuto));
    }

    const editorId = useRef(props.id ? props.id : handleEditorId());

    const handleErrors = (exception)=>{
        var msg = '';
        switch (exception) {
            case 'SaveEntradaException':
                msg = 'Ocurrio un error guardando los cambios, intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
                break;
            default:
                msg = 'No fue posible realizar la acción, por favor intentelo de nuevo, si el problema persiste, comuniquese con nosotros';
                break;
        }
        const error_data= {
            show: true,
            msg: msg
        };
        showError(error_data);
    };

    const handleGuardarEntrada = async() => {
        const raw_data = convertToRaw(editorState.getCurrentContent());
        const pRaw_data = convertToRaw(previousState.current.getCurrentContent());
        var update = false;
        if(raw_data.blocks.length !== pRaw_data.blocks.length){
            update = true;
        } else {
            const actualText = raw_data.blocks[raw_data.blocks.length - 1].text;
            const previousText = pRaw_data.blocks[pRaw_data.blocks.length - 1].text;
            if(actualText !== previousText){
                update = true;
            }
        }
        if(update){
            try {
                const result = await API.put('sanus','/entradas/'+props.entradaId,{
                    body: {
                        historiaId: props.historiaId,
                        editorId: editorId.current,
                        raw_data: raw_data
                    }
                });
                if(result.fail){
                    console.log(result);
                    const error = 'SaveEntradaException';
                    throw error;
                }
                console.log("cambios guardados");
                previousState.current = editorState;
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <Editor 
                        editorState = {editorState}
                        readOnly = {readOnly}
                        wrapperClassName="editor-border"
                        editorClassName="text-editor"
                        onEditorStateChange={estate=>handleEditorStateChange(estate)}
                        onBlur={handleGuardarEntrada}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                            inline: {
                                options: ['bold', 'italic', 'underline']
                                                        }
                        }}
                    />
                </Col>
            </Row>
            <Row className="mt-1 align-items-center">
                <Col className="text-right pr-1">
                    <Button 
                        className='action-button'
                        variant='link'
                        onClick={()=>setReadOnly(!readOnly)}
                    >{readOnly ? <FaLock /> : <FaLockOpen />}</Button>
                </Col>
                <Col className="pl-0" sm={'auto'}>
                    <footer className="text-right blockquote-footer">
                        <cite title="Publicado en">{editorId.current}</cite>
                     </footer>  
                </Col>
            </Row>
            <hr></hr>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorEntradaHistoriaBlock);