import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Spinner, InputGroup, FormControl } from "react-bootstrap";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { useFormFields } from "../libs/hooksLib";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoaderButton from "./loaderButton";

const mapStateToProps = (state, props) => ({
    institucionId: state.institucionId,
    setMessage: props.setMessage, //Para regresar mensajes a quien invoca este componente
    setAlertType: props.setAlertType, // Para regresar tipo de alerta [success, warning, danger]
    usuarioSelectedId: props.usuarioSelectedId, // Data con el id del usuario seleccionado a editar
    onFinish: props.onFinish //Funcion que debe ejecutarse al finalizar la edición del usuario 
});

const mapDispatchToProps = dispatch => ({});

const UserFormPaciente = ({usuarioSelectedId, setMessage, institucionId, setAlertType, onFinish}) => {
    
    const initialData = {
        nombre: "",
        apellido: "",
        identificacion: "",
        email: "",
        telefono: "",
        nacimiento: new Date(),
        estado: true,
        sanusUser: true,
        rol: "paciente",
        institucionId: institucionId,
    };
    const [pacienteData, handlePacienteData] = useFormFields(initialData);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLocked, setIsLocked] = useState(false);

    const userSelectedData = useRef('');
    const isFirstRender = useRef(true);

    useEffect(()=>{
        return function cleanUp(){
            setAlertType('');
            setMessage('');
            userSelectedData.current = '';
        }
    },[setAlertType, setMessage]);

    useEffect(()=>{
        const getUserData = async() => {
            try{
                setIsLoading(true);
                //console.log("recuperando usuario " + usuarioSelectedId);
                const uData = await API.get("sanus","/usuarios/"+usuarioSelectedId,{
                    queryStringParameters: {
                        get: ['nombre','apellido','email','telefono','identificacion','nacimiento','estado','sanusUser'],
                    }
                });
                if(uData.fail){
                    console.log(uData);
                    setAlertType('danger');
                    setMessage('No se puede recuperar la información del usuario');
                } else {
                    userSelectedData.current = {
                        nombre: uData.nombre,
                        apellido: uData.apellido,
                        identificacion: uData.identificacion,
                        email: uData.email,
                        telefono: uData.telefono,
                        nacimiento: new Date(uData.nacimiento),
                        estado: uData.estado,
                        sanusUser: uData.sanusUser,
                        rol: "paciente",
                        institucionId: institucionId,
                    };
                    handlePacienteData({target:{value: userSelectedData.current, type:"cleanUp"}});
                }
                setIsLocked(true);
                setIsLoading(false);
            } catch (error){
                console.log(error);
                setAlertType('danger');
                setMessage('No se puede recuperar la información del usuario');
            }
        }
        if(usuarioSelectedId.length > 0){
            if(isFirstRender.current){
                isFirstRender.current = false;
                getUserData();
            }
        }
    },[handlePacienteData, institucionId, setAlertType, setMessage, usuarioSelectedId])

    /**Estructura errors:
     * {
     *  @param {String} code: Codigo excepcion
     *  @param {String} message: mensaje del error
     * }
     */
    const handleErrors = (exception) => {
        console.log(exception)
        switch (exception.code) {
            case "UnableCreateUser":
                setAlertType('danger');
                setMessage(exception.message)
                break;
            case "InstitucionIdException":
                setAlertType('danger');
                setMessage(exception.message);
                break;
            case "UsernameExistsException":
                setAlertType('warning');
                setMessage('Este paciente ya existe');
                break;
            default:
                setAlertType('warning');
                setMessage(exception);
                break;
        }
    }

    const blank = () => {
        handlePacienteData({target:{value: initialData, type:"cleanUp"}});
    };

    const handleGuardarPaciente = async() => {
        setAlertType('');
        setMessage('')
        setIsSaving(true);
        if(usuarioSelectedId.length > 0){
            const uDataChange = []
            try {
                for(const index in pacienteData){
                    const value = pacienteData[index];
                    if(value !== userSelectedData.current[index]){
                        const data = {
                            propiedad: index,
                            value: value
                        };
                        uDataChange.push(data)
                    }
                }
                const response = await API.put("sanus","/usuarios/"+usuarioSelectedId, {
                    body: {
                        propiedades: uDataChange
                    }
                });
                if(response.fail){
                    throw (response.description);
                }
                setAlertType('success');
                setMessage('Paciente: '+pacienteData.email+' actualizado correctamente');
                onFinish();
                setIsSaving(false);
            } catch (error) {
                handleErrors(error);
                setIsSaving(false);
            }           
        } else {
            try {
                if(!pacienteData.institucionId){
                    const error = {
                        code: 'InstitucionIdException',
                        message: 'Se presento un error, no se encuentra la institución, no se puede crear paciente, intentelo de nuevo, si el problema persiste comuniquese con nosotros'
                    }
                    throw (error);
                }
                const response = await API.post("sanus", "/crearUsuario",{
                    body: pacienteData
                });
                if(response.fail){
                    throw (response.description);
                }
                setAlertType('success');
                setMessage('Paciente: '+pacienteData.email+' creado correctamente');
                blank();
                setIsSaving(false);
            } catch(error) {
                handleErrors(error);
                setIsSaving(false);
            }
        }
    };

    const handleEnableGuardarPaciente = () => {
        if(usuarioSelectedId.length > 0 ){
            return pacienteData.nombre !== userSelectedData.current.nombre ||
            pacienteData.apellido !== userSelectedData.current.apellido ||
            pacienteData.telefono !== userSelectedData.current.telefono ||
            pacienteData.nacimiento !== userSelectedData.current.nacimiento ||
            pacienteData.estado !== userSelectedData.current.estado ||
            pacienteData.sanusUser !== userSelectedData.current.sanusUser
        } else {
            return pacienteData.nombre.length > 3 &&
            pacienteData.apellido.length > 3 &&
            pacienteData.identificacion.length > 3 &&
            pacienteData.email.length > 3 &&
            pacienteData.email.includes('@') &&
            pacienteData.telefono > 0
        }
    }

    return (
        <div>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-3">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Nombre</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Nombre del paciente"
                                    aria-label="nombre"
                                    aria-describedby="nombre"
                                    id="nombre"
                                    value={pacienteData.nombre}
                                    onChange={handlePacienteData}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Apellido</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Apellido del paciente"
                                    aria-label="appelido"
                                    aria-describedby="apellido"
                                    id="apellido"
                                    value={pacienteData.apellido}
                                    onChange={handlePacienteData}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>email</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="correo electrónico del paciente"
                                    aria-label="email"
                                    aria-describedby="email"
                                    id="email"
                                    readOnly = {isLocked}
                                    value={pacienteData.email}
                                    onChange={handlePacienteData}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Telefono</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Número telefónico del paciente"
                                    aria-label="email"
                                    aria-describedby="telefono"
                                    type='number'
                                    id="telefono"
                                    value={pacienteData.telefono}
                                    onChange={handlePacienteData}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Identificación</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Documento de identidad del paciente"
                                    aria-label="identificacion"
                                    aria-describedby="identificacion"
                                    id="identificacion"
                                    readOnly = {isLocked}
                                    value={pacienteData.identificacion}
                                    onChange={handlePacienteData}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text >¿Nacimiento?</InputGroup.Text>
                                </InputGroup.Prepend>
                                <DatePicker
                                    className="form-control"
                                    selected={pacienteData.nacimiento}
                                    value={pacienteData.nacimiento}
                                    onChange={(e)=>{
                                        const event = {
                                            target: {
                                                id:"nacimiento", 
                                                value: e
                                            }
                                        };
                                        handlePacienteData(event);
                                    }}
                                    dateFormat='dd/MM/yyyy'
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode='select'
                                    fixedHeight
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Checkbox 
                                        checked={pacienteData.estado}
                                        id="estado"
                                        onChange={handlePacienteData}
                                    />
                                </InputGroup.Prepend>
                                <FormControl 
                                    readOnly
                                    value="¿Activo?"
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Checkbox 
                                        checked={pacienteData.sanusUser}
                                        id="sanusUser"
                                        onChange={handlePacienteData}
                                    />
                                </InputGroup.Prepend>
                                <FormControl 
                                    readOnly
                                    value="¿Usuario de SANUS?"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col className="text-right">
                            <LoaderButton
                                variant="outline-primary"
                                isLoading={isSaving}
                                disabled={!handleEnableGuardarPaciente()}
                                onClick={()=>handleGuardarPaciente()}
                            > Guardar paciente</LoaderButton>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFormPaciente);