import React, { useState, useEffect, useRef } from "react";
import { Col, Spinner, Navbar, 
    Dropdown, Nav, Modal } 
    from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { FaUser, FaUserMd, FaUserInjured, 
    FaUserNurse, FaUserSecret } 
    from "react-icons/fa";
import { BiMessageSquareError } from "react-icons/bi";
import logo from "../img/Logo.svg";

const mapStateToProps = state => ({
    userId: state.userId,
    showModalError: state.onErrors.show,
    errorMsg: state.onErrors.msg
});

const mapDispatchToProps = dispatch => ({
    setInstitucionAlias(institucion_alias){
        dispatch({
            type: "UPDATE_INSTITUCION_ALIAS",
            institucion_alias
        })
    },
    setInstitucionId(institucion_id){
        dispatch({
            type: "UPDATE_INSTITUCION_ID",
            institucion_id
        })
    },
    setUserRol(user_rol){
        dispatch({
            type: "UPDATE_USER_ROL",
            user_rol
        })
    },
    showError(error_data){
        dispatch({
            type: "SHOW_ERROR",
            error_data
        })
    },
});

const Header = (
    {
        userId, setInstitucionAlias, 
        setInstitucionId, showError,
        showModalError, errorMsg, setUserRol
    }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [institucion, setInstitucion] = useState("");

    const history = useHistory();

    const userData = useRef({nombre: "",apellido: ""});

    const modalErrorTitle = useRef(<BiMessageSquareError size={30}/>);

    useEffect(()=>{
        const getUserData = async() => {
            try{
                //Recuperar institucionId, nombre, apellido
                const uData = await API.get("sanus", "/usuarios/"+userId,{
                    queryStringParameters: {
                        get: ['nombre', 'apellido', 'institucionId', 'rol']
                    }
                });
                if(uData.fail){
                    throw (uData.description);
                } else {
                    userData.current = uData;
                    setUserRol(uData.rol);
                    setIsLoading(false);
                    API.get("sanus","/institucion/"+uData.institucionId,{
                        queryStringParameters: {
                            get: ['alias']
                        }
                    }).then(value => {
                        setInstitucion(" | "+value.alias.charAt(0).toUpperCase() + value.alias.slice(1));
                        setInstitucionAlias(value.alias.charAt(0).toUpperCase() + value.alias.slice(1));
                        setInstitucionId(uData.institucionId);
                    }).catch(error=>{
                        console.log(error);
                    });
                }
            } catch (error){
                console.log(error)
            }
        }
        getUserData();
    },[userId, setIsLoading, setInstitucionAlias, setInstitucionId, setUserRol]);

    const handleLogout = async() => {
        await Auth.signOut()
        history.push("/login")
    }

    const renderuserIcon = () => {
        switch (userData.current.rol) {
            case "especialista":
                return <FaUserMd size={'20'}/>
            case "paciente":
                return <FaUserInjured size={'20'}/>
            case "manager":
                return <FaUserSecret size={'20'}/>
            case "coordinador":
                return <FaUserNurse size={'20'}/>
            default:
                return <FaUser size={'20'}/>
        }
    }

    const handleCloseModalErrors = () => {
        const error_data = {
            show: false,
            msg: ''
        };
        showError(error_data)
    };

    return (
        <header>
            <Modal 
                show={showModalError}
                centered
                onHide={()=>handleCloseModalErrors()}
            >
                <Modal.Header closeButton>{modalErrorTitle.current}</Modal.Header>
                <Modal.Body>{errorMsg}</Modal.Body>
            </Modal>
            <Navbar expand="lg">
                <Navbar.Brand >
                    <img
                        alt="SANUS Logo"
                        src={logo}
                        className="header-logo"
                    ></img>
                    <span className="text-header-logo">&nbsp;SANUS</span>{institucion}
                </Navbar.Brand>    
                <Nav className="mr-auto">
      
                </Nav>
                <Col className="text-right">
                    <span className="text-header capitalize">{userData.current.nombre} {userData.current.apellido}&nbsp;&nbsp;</span>
                </Col>
                <Dropdown> 
                    {isLoading ? <Spinner animation="border" variant="primary" /> : 
                        <div>
                            <Dropdown.Toggle className="btn-user-header" variant="outline-primary" id="dropdown-user">
                                {renderuserIcon()}
                            </Dropdown.Toggle>
                            
                            <Dropdown.Menu className="dropdown-menu-right">
                                <Dropdown.Item href="#/action-1">Mi perfil</Dropdown.Item>
                                {/*<Dropdown.Item href="#/action-2">Mi cuenta</Dropdown.Item>*/}
                                <Dropdown.Item onClick={()=>handleLogout()} >Cerrar sesión</Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    }
                </Dropdown>  
                   
            </Navbar>
        </header>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);