import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Spinner, Modal, 
    Button, Alert, DropdownButton, Dropdown 
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { API } from "aws-amplify";
import { connect } from "react-redux";
import logo from "../img/Logo.svg";
import { IoMdAdd, IoMdCreate, IoMdRefresh } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import FormServicio from "./formServicio";

const mapStateToProps = state => ({
    institucionId: state.institucionId,
});

const mapDispatchToProps = dispatch => ({
    showError(error_data){
        dispatch({
            type: "SHOW_ERROR",
            error_data
        })
    },
});

const ServiciosBlock = ({institucionId, showError}) => {

    const [showModalServicio, setShowModalServicio] = useState(false);
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [isServicioSelected, setIsServicioSelected] = useState(false);
    const [servicioSelectedId, setServicioSelectedId] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const tableData = useRef([]);
    const isFirstRender = useRef(true);
    const bootstrapTableMsg = useRef("No hay servicios que mostrar");
    const columns = useRef([
        {
            dataField: "nombre", 
            text: "Servicio",
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "estado", 
            text: "Estado",
            headerStyle: () => {
                return { width: '15%'}
            },
        }
    ]);

    const handleErrors = useCallback((exception) => {
        console.log(exception);
        var msg = '';
        switch (exception) {
            case "DeleteServicioException":
                msg = 'No fue posible eliminar el servicio';
                break;
            case "GetServiciosDataException":
                msg = 'No fue posible recuperar la información del servicio';
                break;
            case "MissingInstitucionIdException":
                msg = 'Se encontro un error de configuración, por favor cierre e inicie sesión nuevamente';
                break;
            default:
                msg = 'No fue posible realizar la acción, por favor intentelo de nuevo, si el problema persiste, comuniquese con nosotros'
                break;
        }
        const error_data= {
            show: true,
            msg: msg
        };
        showError(error_data)
    },[showError]);

    const getServicesData = useCallback(async() => {
        setIsLoading(true);
        setIsServicioSelected(false);
        setServicioSelectedId('');
        tableData.current = []
        try{
            const servicesData = await API.get("sanus","/getServicios",{
                queryStringParameters: {
                    institucionId: institucionId,
                    get:['servicioId','nombre','estado'],
                }
            });
            if(servicesData.fail){
                console.log(servicesData);
                const error = 'GetServiciosDataException';
                throw error;
            }
            tableData.current = servicesData;
            for (let index = 0; index < tableData.current.length; index++) {
                const element = tableData.current[index];
                element.estado = (element.estado ? 'Disponible' : 'No disponible');
            }
            setIsLoading(false);
        } catch(error){
            handleErrors(error);
        }
    },[handleErrors, institucionId]);

    useEffect(()=>{
        if(isFirstRender.current){
            if(institucionId.length > 0){
                getServicesData();
            } else {
                handleErrors("MissingInstitucionIdException");
            }
        }
    },[institucionId, getServicesData, handleErrors])

    const handleOnSelect = (row, isSelect) => {
        if (isSelect){
            setServicioSelectedId(row.servicioId);
            setIsServicioSelected(true);
        } else {
            setServicioSelectedId("");
            setIsServicioSelected(false);
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit:  true,
        onSelect: handleOnSelect
    };

    const handleNuevoServicio = () => {
        setShowModalServicio(true);
    };

    const handleEditarServicio = () => {
        setShowModalServicio(true);
    };

    const handleDeleteServicio = async() => {
        try{
            if(isServicioSelected){
                const result = await API.del("sanus", "/servicios/"+servicioSelectedId);
                if(result.fail){
                    console.log(result.description)
                    const error = 'DeleteServicioException';
                    throw error;
                }
                getServicesData();
            }
        } catch (error){
            handleErrors(error);
        }
    };

    const handleCloseModalServicio = () => {
        setShowModalServicio(false);
        if(servicioSelectedId.length > 0){
            getServicesData();
        }
    };

    return (
        <div>
            <Modal 
                show = {showModalServicio}
                onHide = {()=>handleCloseModalServicio()}
            >
                <Modal.Header closeButton>
                    Nuevo Servicio
                </Modal.Header>
                <Modal.Body>
                    <FormServicio 
                        setAlertType={setAlertType} 
                        setMessage={setMessage} 
                        servicioSelectedId={servicioSelectedId}
                        onFinish={handleCloseModalServicio}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {message.length > 0 ? <Alert variant={alertType} >{message}</Alert>:
                    <img
                        alt="SANUS Logo"
                        src={logo}
                        className="modal-footer-logo"
                    ></img>}
                </Modal.Footer>
            </Modal>
            <Row className="mt-3 mb-1 justify-content-end">
                <Col sm={'auto'} className="pr-1">
                    <Button 
                        onClick={()=>getServicesData()} 
                        title="Volver a cargar" 
                        variant="outline-secondary"
                    ><IoMdRefresh /></Button>
                </Col>
                <Col sm={'auto'} className="pr-1 pl-1">
                    <Button 
                        onClick={()=>handleNuevoServicio()} 
                        title="Crear servicio" 
                        variant="outline-primary"
                    ><IoMdAdd /></Button>
                </Col>
                <Col sm={'auto'} className="pl-0 pr-0">
                    <Button 
                        title="Editar servicio" 
                        variant="outline-warning" 
                        className="mr-1 ml-1"
                        disabled={!isServicioSelected}
                        onClick = {()=>handleEditarServicio()}
                    ><IoMdCreate /></Button>
                </Col>
                 <Col sm={'auto'} className="pl-1">
                     <DropdownButton 
                        variant="outline-danger" 
                        id="borrarServicio" 
                        title={<MdDelete />}
                        disabled={!isServicioSelected}
                    >
                        <Dropdown.Item href="#/action-1">¿Está seguro?</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={()=>handleDeleteServicio()}
                        >Si, borra este servicio</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-3">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col>
                            <BootstrapTable
                                keyField="servicioId"
                                data={tableData.current}
                                columns={columns.current}
                                pagination={paginationFactory()}
                                noDataIndication={bootstrapTableMsg.current}
                                selectRow={selectRow}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiciosBlock);