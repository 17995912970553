import { useState } from "react";

export const useFormFields = (initialState) => {
    const [fields, setValues] = useState(initialState);
    
    return [
        fields,
        event => {
            if(event.target.type === "checkbox"){
                setValues({
                    ...fields,
                    [event.target.id]: event.target.checked
                })
            } else {
                if(event.target.type === "cleanUp"){
                    setValues(event.target.value);
                } else {
                    setValues({
                        ...fields,
                        [event.target.id]: event.target.value
                    });
                }
            }
        } 
    ];
};