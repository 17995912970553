import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Spinner, 
    InputGroup, FormControl, Carousel } 
    from "react-bootstrap";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { useFormFields } from "../libs/hooksLib";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoaderButton from "./loaderButton";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { FaCommentMedical } from "react-icons/fa";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Chip from '@material-ui/core/Chip';

const mapStateToProps = (state, props) => ({
    institucionId: state.institucionId,
    setMessage: props.setMessage, //Para regresar mensajes a quien invoca este componente
    setAlertType: props.setAlertType, // Para regresar tipo de alerta [success, warning, danger]
    usuarioSelectedId: props.usuarioSelectedId, // Data con el id del usuario seleccionado a editar
    onFinish: props.onFinish //Funcion que debe ejecutarse al finalizar la edición del usuario 
});

const mapDispatchToProps = dispatch => ({});

const UserFormEspecialista = (
    {
        institucionId, 
        setMessage, 
        setAlertType,
        usuarioSelectedId,
        onFinish
    }) => {
    const initialData = {
        nombre: "",
        apellido: "",
        identificacion: "",
        email: "",
        telefono: "",
        nacimiento: new Date(),
        estado: true,
        sanusUser: true,
        rol: "especialista",
        institucionId: institucionId,
        titulo: '',
        especialidad: '',
        servicios: []
    };
    const [especialistaData, handleEspecialistaData] = useFormFields(initialData);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingServices, setIsLoadingServices] = useState(true);
    const [isLocked, setIsLocked] = useState(false);
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [renderSS ,setRenderSS] = useState(false);
    const [okPrepareTable, setOkPrepareTable] = useState(false);

    const userSelectedData = useRef('');
    const isFirstRender = useRef(true);
    const bootstrapTableMsg = useRef("No hay servicios que mostrar");
    const columns = useRef([
        {
            dataField: "nombre", 
            text: "Servicio",
            style: {
                textTransform: "capitalize"
            }
        },
    ]);
    const paginationOptions = useRef({
        disablePageTitle: true,
        hideSizePerPage: true
    });

    const servicesAvailables = useRef([]);
    const servic = useRef([]);

    const prepareTable = useCallback(()=>{
        const tmpServicios = especialistaData.servicios;
        setIsLoadingServices(true);
        setTableData([]);
        const localTable = [];
        for (let index = 0; index < servicesAvailables.current.length; index++) {
            const element = servicesAvailables.current[index];
            if(!tmpServicios.includes(element.servicioId)){
                localTable.push(element);
            }
        }
        setTableData(localTable);
        setIsLoadingServices(false);
    },[especialistaData.servicios]);

    useEffect(()=>{
        return function cleanUp(){
            setAlertType('');
            setMessage('');
            userSelectedData.current = '';
        }
    },[setAlertType, setMessage]);

    useEffect(()=>{
        const getServicios = async() => {
            try{
                const servicesData = await API.get("sanus","/getServicios",{
                    queryStringParameters: {
                        institucionId: institucionId,
                        get:['servicioId','nombre','estado'],
                    }
                });
                if(servicesData.fail){
                    console.log(servicesData);
                    setAlertType('danger');
                    setMessage('No se pueden recuperar los servicios institucionales');
                }
                for (let index = 0; index < servicesData.length; index++) {
                    const element = servicesData[index];
                    if(element.estado){
                        servicesAvailables.current.push(element)
                    }
                }
                if(usuarioSelectedId.length === 0){
                    prepareTable();
                }
                setRenderSS(true);
            } catch (error){
                console.log(error);
                setAlertType('danger');
                setMessage('No se pueden recuperar los servicios institucionales');
                setIsLoadingServices(false);
            }
        };

        const getUserData = async() => {
            try{
                setIsLoading(true);
                //console.log("recuperando usuario " + usuarioSelectedId);
                const uData = await API.get("sanus","/usuarios/"+usuarioSelectedId,{
                    queryStringParameters: {
                        get: ['nombre','apellido','email','telefono',
                        'identificacion','nacimiento','estado', 'titulo',
                    'especialidad','servicios'],
                    }
                });
                if(uData.fail){
                    console.log(uData);
                    setAlertType('danger');
                    setMessage('No se puede recuperar la información del usuario');
                } else {
                    for (let index = 0; index < uData.servicios.length; index++) {
                        const element = uData.servicios[index];
                        servic.current.push(element);
                    }
                    userSelectedData.current = {
                        nombre: uData.nombre,
                        apellido: uData.apellido,
                        identificacion: uData.identificacion,
                        email: uData.email,
                        telefono: uData.telefono,
                        nacimiento: new Date(uData.nacimiento),
                        estado: uData.estado,
                        titulo: uData.titulo,
                        especialidad: uData.especialidad,
                        servicios: uData.servicios,
                        rol: "especialista",
                        institucionId: institucionId,
                    };
                    handleEspecialistaData({target:{value: userSelectedData.current, type:"cleanUp"}});
                    setOkPrepareTable(true);
                }
                setIsLocked(true);
                setIsLoading(false);
            } catch (error){
                console.log(error);
                setAlertType('danger');
                setMessage('No se puede recuperar la información del usuario');
            }
        }

        if(isFirstRender.current){
            isFirstRender.current = false;
            getServicios();
            if(usuarioSelectedId.length > 0){
                getUserData();
            }
        }
    },[handleEspecialistaData, institucionId, setAlertType, setMessage, usuarioSelectedId, prepareTable])
    
    useEffect(()=>{
        if(okPrepareTable || renderSS){
            prepareTable();
        }
    },[okPrepareTable, renderSS, prepareTable])

    /**Estructura errors:
    * {
     *  @param {String} code: Codigo excepcion
     *  @param {String} message: mensaje del error
    * }
     */
    const handleErrors = (exception) => {
        switch (exception.code) {
            case "UnableCreateUser":
                setAlertType('danger');
                setMessage(exception.message)
                break;
            case "InstitucionIdException":
                setAlertType('danger');
                setMessage(exception.message);
                break;
            case "UsernameExistsException":
                setAlertType('warning');
                setMessage('Este especialista ya existe');
                break;
            default:
                setAlertType('warning');
                setMessage(exception);
                break;
        }
    };

    const blank = () => {
        handleEspecialistaData({target:{value: initialData, type:"cleanUp"}});
    };

    const handleGuardarEspecialista = async() => {
        setAlertType('');
        setMessage('')
        setIsSaving(true);
        if(usuarioSelectedId.length > 0){
            const uDataChange = []
            try {
                for(const index in especialistaData){
                    const value = especialistaData[index];
                    if(value instanceof Array){
                        if(JSON.stringify(value) !== JSON.stringify(servic.current)){
                            const data = {
                                propiedad: 'servicios',
                                value: value
                            };
                            uDataChange.push(data)
                        }
                    } else {
                        if(value !== userSelectedData.current[index]){
                            const data = {
                                propiedad: index,
                                value: value
                            };
                            uDataChange.push(data)
                        }
                    }
                }
                const response = await API.put("sanus","/usuarios/"+usuarioSelectedId, {
                    body: {
                        propiedades: uDataChange
                    }
                });
                if(response.fail){
                    throw (response.description);
                }
                setAlertType('success');
                setMessage('Especialista: '+especialistaData.email+' actualizado correctamente');
                onFinish();
                setIsSaving(false);
            } catch (error) {
                handleErrors(error);
                setIsSaving(false);
            }           
        } else {
            try {
                if(!especialistaData.institucionId){
                    const error = {
                        code: 'InstitucionIdException',
                        message: 'Se presento un error, no se encuentra la institución, no se puede crear el especialista, intentelo de nuevo, si el problema persiste comuniquese con nosotros'
                    }
                    throw (error);
                }
                const response = await API.post("sanus", "/crearUsuario",{
                    body: especialistaData
                });
                if(response.fail){
                    throw (response.description);
                }
                setAlertType('success');
                setMessage('Especialista: '+especialistaData.email+' creado correctamente');
                blank();
                setIsSaving(false);
            } catch(error) {
                handleErrors(error);
                setIsSaving(false);
            }
        }
        if(onFinish){
            onFinish();
        }
    };

    const handleEnableGuardarEspecialista = () => {
        if(usuarioSelectedId.length > 0){
            return especialistaData.nombre !== userSelectedData.current.nombre ||
            especialistaData.apellido !== userSelectedData.current.apellido ||
            especialistaData.telefono !== userSelectedData.current.telefono ||
            especialistaData.nacimiento !== userSelectedData.current.nacimiento ||
            especialistaData.estado !== userSelectedData.current.estado ||
            especialistaData.titulo !== userSelectedData.current.titulo ||
            especialistaData.especialidad !== userSelectedData.current.especialidad ||
            (JSON.stringify(especialistaData.servicios) !== JSON.stringify(servic.current) &&
            especialistaData.servicios.length > 0)
        } else {
            return especialistaData.nombre.length > 3 &&
            especialistaData.apellido.length > 3 &&
            especialistaData.telefono > 0 &&
            especialistaData.identificacion.length > 3 &&
            especialistaData.email.length > 3 &&
            especialistaData.email.includes("@") &&
            especialistaData.titulo.length > 3 &&
            especialistaData.especialidad.length > 3 &&
            especialistaData.servicios.length > 0
        }
    };

    const handleSelect = (selectedIndex, e) => {
        setPage(selectedIndex);
    };

    // const handleOnSelectAll = (isSelect, rows) => {
    //     // No funciono select all, entraba en conflicto con la velocidad de
    //     // actualziación del estado especialistaData.servicios 
        
    //     // 28042021 Se realizan cambios en la logica para presentar la tabla, 
    //     // no se ha propado los efectos de este cambio cuando se seleccione todos los servicios
    //     if(isSelect){
    //         const servicios = []
    //         for (let index = 0; index < rows.length; index++) {
    //             const element = rows[index];
    //             servicios.push(element.servicioId)
    //         }
    //         const event = {
    //             target: {
    //                 id: "servicios", 
    //                 value: servicios
    //             }
    //         };
    //         handleEspecialistaData(event);
    //         prepareTable();
    //     } else {
    //         const blank = [];
    //         const event = {
    //             target: {
    //                 id: "servicios", 
    //                 value: blank
    //             }
    //         };
    //         handleEspecialistaData(event);
    //     }
    // };

    const handleOnSelect = (row, isSelect) => {
        const servicios = especialistaData.servicios;
        if (isSelect){
            servicios.push(row.servicioId);
        } else {
            const index = servicios.indexOf(row.servicioId);
            servicios.splice(index, 1);
        }
        const event = {
            target: {
                id: "servicios", 
                value: servicios
            }
        };
        handleEspecialistaData(event);
        prepareTable();
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        clickToEdit:  true,
        selected: [],
        hideSelectAll: true,
        onSelect: handleOnSelect,
        // onSelectAll: handleOnSelectAll
    };

    const handleQuitServicio = (servicioId) => {
        const ss = especialistaData.servicios;
        const index = ss.indexOf(servicioId);
        ss.splice(index, 1);
        const event = {
            target: {
                id: "servicios", 
                value: ss
            }
        };
        handleEspecialistaData(event);
        prepareTable();
    };

    const RenderServiciosSeleccionados = () => {
        if(especialistaData.servicios.length > 0){
            return(
                <div>
                    <hr></hr>
                    <Row className="mb-2">
                        <Col>
                            Servicios seleccionados:
                        </Col>
                    </Row>
                    <Row>
                        {especialistaData.servicios.map((servicio)=>{
                            var nombreServicio = '';
                            var key = 'x01'
                            for (let index = 0; index < servicesAvailables.current.length; index++) {
                                const element = servicesAvailables.current[index];
                                if(element.servicioId === servicio){
                                    nombreServicio = element.nombre;
                                    key = element.servicioId;
                                    break;
                                }
                            }
                            return(
                                <Col className="mt-1" key={key} sm={'auto'}>
                                    <Chip
                                        icon={<FaCommentMedical size={20}/>}
                                        label={nombreServicio}
                                        onClick={()=>handleQuitServicio(key)}
                                        onDelete={()=>handleQuitServicio(key)}
                                        variant="outlined"
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            )
        } else {
            return (
                <div>
                </div>
            )
        }
    }

    return (
        <div>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-3">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            : 
                <div>
                    <Carousel 
                        controls={false}
                        indicators={false}
                        activeIndex={page} 
                        onSelect={handleSelect}
                        interval={null}
                    >
                        <Carousel.Item >
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text>Nombre</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            placeholder="Nombre del especialista"
                                            aria-label="nombre"
                                            aria-describedby="nombre"
                                            id="nombre"
                                            value={especialistaData.nombre}
                                            onChange={handleEspecialistaData}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text>Apellido</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            placeholder="Apellido del especialista"
                                            aria-label="appelido"
                                            aria-describedby="apellido"
                                            id="apellido"
                                            value={especialistaData.apellido}
                                            onChange={handleEspecialistaData}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text>email</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            placeholder="correo electrónico del especialista"
                                            aria-label="email"
                                            aria-describedby="email"
                                            id="email"
                                            readOnly = {isLocked}
                                            value={especialistaData.email}
                                            onChange={handleEspecialistaData}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text>Telefono</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            placeholder="Número telefónico del especialista"
                                            aria-label="email"
                                            aria-describedby="telefono"
                                            type='number'
                                            id="telefono"
                                            value={especialistaData.telefono}
                                            onChange={handleEspecialistaData}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text>Identificación</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            placeholder="Documento de identidad del especialista"
                                            aria-label="identificacion"
                                            aria-describedby="identificacion"
                                            id="identificacion"
                                            readOnly = {isLocked}
                                            value={especialistaData.identificacion}
                                            onChange={handleEspecialistaData}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text >¿Nacimiento?</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <DatePicker
                                            className="form-control"
                                            selected={especialistaData.nacimiento}
                                            value={especialistaData.nacimiento}
                                            onChange={(e)=>{
                                                const event = {
                                                    target: {
                                                        id:"nacimiento", 
                                                        value: e
                                                    }
                                                };
                                                handleEspecialistaData(event);
                                            }}
                                            dateFormat='dd/MM/yyyy'
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode='select'
                                            fixedHeight
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Checkbox 
                                                checked={especialistaData.estado}
                                                id="estado"
                                                onChange={handleEspecialistaData}
                                            />
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            readOnly
                                            value="¿Activo?"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text>Título</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            placeholder="Psicologo, Doctor"
                                            aria-label="titulo"
                                            aria-describedby="titulo"
                                            id="titulo"
                                            value={especialistaData.titulo}
                                            onChange={handleEspecialistaData}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text>Especialidad</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl 
                                            placeholder="Pediatra, neurologo, salud ocupacional"
                                            aria-label="especialidad"
                                            aria-describedby="especialidad"
                                            id="especialidad"
                                            value={especialistaData.especialidad}
                                            onChange={handleEspecialistaData}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Servicios que puede atender este especialista:
                                </Col>
                            </Row>
                            <Row>
                                {isLoadingServices ? 
                                    <Col className="text-center mt-3">
                                        <Spinner animation="border" variant="primary" />
                                    </Col>
                                :
                                    <Col>
                                        <BootstrapTable
                                            keyField="servicioId"
                                            data={tableData}
                                            columns={columns.current}
                                            pagination={paginationFactory(paginationOptions.current)}
                                            noDataIndication={bootstrapTableMsg.current}
                                            selectRow={selectRow}
                                        />
                                    </Col>
                                }
                            </Row>
                            {renderSS ? <RenderServiciosSeleccionados /> : <></>}
                        </Carousel.Item>
                    </Carousel>
                    <hr></hr>
                    <Row>
                        <Col className="text-left">
                            {page === 0 ? '' : 
                                <LoaderButton 
                                    variant="outline-secondary" 
                                    onClick={()=>setPage(0)}
                                ><MdNavigateBefore /></LoaderButton>
                            }
                        </Col>
                        <Col className="text-right">
                            {page === 1 ? 
                                <LoaderButton
                                    variant="outline-primary"
                                    isLoading={isSaving}
                                    disabled={!handleEnableGuardarEspecialista()}
                                    onClick={()=>handleGuardarEspecialista()}
                                > Guardar especialista</LoaderButton>
                            : 
                                <LoaderButton 
                                    variant="outline-secondary" 
                                    onClick={()=>setPage(1)}
                                ><MdNavigateNext /></LoaderButton>
                            }
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFormEspecialista);