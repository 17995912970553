import React from "react"
import { Route, Switch } from "react-router-dom";
import Login from "./containers/login.js";
import Sanus from "./containers/sanus.js";

export default function Routes (){
    return (
        <Switch>
            <Route exact path="/">
                <Sanus />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
        </Switch>
    );
}