import React, { useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import UnderConstruction from "../components/underConstruction";
import PacientesBlock from "../components/pacientesBlock";
import Historia from '../components/historia';

const mapStateToProps = state => ({
    userRol: state.userRol,
    usuarioSelectedId: state.usuarioSelectedId //usuarioId de un paciente seleccionado al que se le consultará/editará la historia clinica
});

const mapDispatchToProps = dispatch => ({
    showError(error_data){
        dispatch({
            type: "SHOW_ERROR",
            error_data
        })
    },
});

const ConsulorioVirtual = ({userRol, showError, usuarioSelectedId}) => {

    const [activeTab, setActiveTab] = useState('pacientes');

    return (
        <div>
            <Row>
                <Col>
                    <Tabs
                        activeKey={activeTab}
                        id="institucion-menu"
                        onSelect={(k)=>setActiveTab(k)}
                    >
                        {userRol === 'especialista' ? 
                            // Se muestra si al consultar el schedule el especialista actualmente esta con un 
                            // paciente, cargará automáticamente la historia clinica del paciente
                            // se activara la tab automáticamente si esta en consulta
                            <Tab eventKey="consulta" title="Consulta">
                                {activeTab === "consulta" ? <UnderConstruction /> : <></>}
                            </Tab>
                        : 
                            <></>
                        }
                        <Tab eventKey="pacientes" title="Pacientes">
                            {activeTab === "pacientes" ? 
                                (usuarioSelectedId ? <Historia /> : <PacientesBlock /> ) 
                            : <></>}
                        </Tab>
                        <Tab eventKey="telemedicina" title="Telemedicina" >
                            {activeTab === "telemedicina" ? <UnderConstruction /> : <></>}
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsulorioVirtual);