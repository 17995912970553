import { createStore } from "redux";

const initialState = {
    userId: "",
    institucionAlias: "",
    institucionId: null,
    userRol: null,
    usuarioSelectedId: null,
    onErrors: {
        show: false,
        msg: ''
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "USER_PERFORM_LOGIN":
            return {
                ...state,
                userId: action.auth_userid
            }  
        case "UPDATE_INSTITUCION_ALIAS":
            return {
                ...state,
                institucionAlias: action.institucion_alias
            }   
        case "UPDATE_INSTITUCION_ID":
            return {
                ...state,
                institucionId: action.institucion_id
            }
        case "UPDATE_USER_ROL":
            return {
                ...state,
                userRol: action.user_rol
            }
        case "SET_USUARIO_SELECTED_ID":
            return {
                ...state,
                usuarioSelectedId: action.usuario_selected_id
            }   
        case "SHOW_ERROR":
            return {
                ...state,
                onErrors: action.error_data
            }  
        default:
            break;
    }
    return state;
};

export default createStore(reducer);