import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Tabs, Tab, Spinner } from "react-bootstrap";
import PacientesBlock from "../components/pacientesBlock";
import ServiciosBlock from "../components/serviciosBlock";
import EspecialistasBlock from "../components/especialistasBlock";
import CoordinadoresBlock from "../components/coordinadoresBlock";
import { connect } from "react-redux";

const mapStateToProps = state => ({
    userRol: state.userRol
});

const mapDispatchToProps = dispatch => ({
    showError(error_data){
        dispatch({
            type: "SHOW_ERROR",
            error_data
        })
    },
});

const Institucion = ({userRol, showError}) => {

    const [activeTab, setActiveTab] = useState('pacientes');
    const [isLoading, setIsLoading] = useState(true);
    
    const isFirstRender = useRef(true);

    useEffect(()=>{
        if(isFirstRender.current){
            isFirstRender.current = false;
            if(!userRol){
                console.log("El usuario no tiene rol asignado")
            }
            setIsLoading(false);
        }
    },[setIsLoading, userRol])

    return(
        <div>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-3">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col>
                            <Tabs 
                                activeKey={activeTab}
                                id="institucion-menu"
                                onSelect={(k)=>setActiveTab(k)}
                            >
                                <Tab eventKey="pacientes" title="Pacientes">
                                    {activeTab === "pacientes" ? <PacientesBlock /> : <></>}
                                </Tab>
                                <Tab eventKey="especialistas" title="Especialistas">
                                    {activeTab === "especialistas" ? <EspecialistasBlock /> : <></>}
                                </Tab>
                                <Tab eventKey="servicios" title="Servicios" >
                                    {activeTab === "servicios" ? <ServiciosBlock /> : <></>}
                                </Tab>
                                {/**Esto solo debe verlo el manager */}
                                {userRol === 'manager' ? 
                                    <Tab eventKey="coordinadores" title="Coordinadores" > 
                                        {activeTab === "coordinadores" ? <CoordinadoresBlock /> : <></>}
                                    </Tab>
                                : <></>
                                }
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Institucion);