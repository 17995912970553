import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Spinner, InputGroup, FormControl } from "react-bootstrap";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from "./loaderButton";

const mapStateToProps = (state, props) => ({
    institucionId: state.institucionId,
    setMessage: props.setMessage, //Para regresar mensajes a quien invoca este componente
    setAlertType: props.setAlertType, // Para regresar tipo de alerta [success, warning, danger]
    servicioSelectedId: props.servicioSelectedId, // Data con el id del usuario seleccionado a editar
    onFinish: props.onFinish //Funcion que debe ejecutarse al finalizar la edición del usuario 
});

const mapDispatchToProps = dispatch => ({});

const FormServicio = (
    {
        institucionId, 
        setMessage, 
        setAlertType, 
        servicioSelectedId,
        onFinish,
    }) => {
        const initialData = {
            institucionId: institucionId,
            nombre: '',
            descripcion: '',
            estado: true
        };
        const [servicioData, handleServicioData] = useFormFields(initialData);
        const [isSaving, setIsSaving] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const servicioSelectedData = useRef('');
        const isFirstRender = useRef(true);

        useEffect(()=>{
            return function cleanUp(){
                setAlertType('');
                setMessage('');
                servicioSelectedData.current = '';
            }
        },[setAlertType, setMessage]);

        useEffect(()=>{
            const getServicioData = async() => {
                try{
                    setIsLoading(true);
                    const sData = await API.get("sanus","/servicios/"+servicioSelectedId,{
                        queryStringParameters: {
                            get: ['servicioId','nombre','descripcion','estado']
                        }
                    });
                    if(sData.fail){
                        console.log(sData);
                        setAlertType('danger');
                        setMessage('No se puede recuperar la información del servicio');
                    } else {
                        servicioSelectedData.current = {
                            nombre: sData.nombre.charAt(0).toUpperCase() + sData.nombre.slice(1),
                            descripcion: sData.descripcion.charAt(0).toUpperCase() + sData.descripcion.slice(1),
                            estado: sData.estado,
                            institucionId: institucionId
                        };
                        handleServicioData({target:{value: servicioSelectedData.current, type:"cleanUp"}});
                    }
                    setIsLoading(false);
                } catch(error){
                    console.log(error);
                    setAlertType('danger');
                    setMessage('No se puede recuperar la información del servicio');
                }
            };

            if(servicioSelectedId.length > 0){
                if(isFirstRender.current){
                    isFirstRender.current = false;
                    getServicioData();
                }
            }
        },[handleServicioData, institucionId, setAlertType, setMessage, servicioSelectedId]);

        /**Estructura errors:
     * {
     *  @param {String} code: Codigo excepcion
     *  @param {String} message: mensaje del error
     * }
     */
    const handleErrors = (exception) => {
        console.log(exception)
        switch (exception.code) {
            case "UnableCreateServicio":
                setAlertType('danger');
                setMessage(exception.message)
                break;
            case "InstitucionIdException":
                setAlertType('danger');
                setMessage(exception.message);
                break;
            case "ServicioExistsException":
                setAlertType('warning');
                setMessage('Este servicio ya existe');
                break;
            default:
                setAlertType('warning');
                setMessage(exception);
                break;
        }
    }

    const blank = () => {
        handleServicioData({target:{value: initialData, type:"cleanUp"}});
    };

    const handleGuardarServicio = async() => {
        setAlertType('');
        setMessage('')
        setIsSaving(true);
        if(servicioSelectedId.length > 0){
            const sDataChange = []
            try{
                for(const index in servicioData){
                    const value = servicioData[index];
                    if(value !== servicioSelectedData.current[index]){
                        const data = {
                            propiedad: index,
                            value: value
                        };
                        sDataChange.push(data)
                    }
                }
                const response = await API.put("sanus","/servicios/"+servicioSelectedId, {
                    body: {
                        propiedades: sDataChange
                    }
                });
                if(response.fail){
                    throw (response.description);
                }
                setAlertType('success');
                setMessage('Servicio: '+servicioData.nombre+' actualizado correctamente');
                onFinish();
                setIsSaving(false);
            } catch(error){
                handleErrors(error);
                setIsSaving(false);
            }
        } else {
            try{
                if(!servicioData.institucionId){
                    const error = {
                        code: 'InstitucionIdException',
                        message: 'Se presento un error, no se encuentra la institución, no se puede crear el servicio, intentelo de nuevo, si el problema persiste comuniquese con nosotros'
                    }
                    throw (error);
                }
                const response = await API.post("sanus", "/crearServicio",{
                    body: servicioData
                });
                if(response.fail){
                    throw (response.description);
                }
                setAlertType('success');
                setMessage('Servicio: '+servicioData.nombre+' creado correctamente');
                blank();
                setIsSaving(false);
            } catch(error){
                handleErrors(error);
                setIsSaving(false);
            }
        }
    };

    const handleEnableGuardarServicio = () => {
        if(servicioSelectedId.length > 0){
            return servicioData.nombre !== servicioSelectedData.current.nombre ||
            servicioData.descripcion !== servicioSelectedData.current.descripcion ||
            servicioData.estado !== servicioSelectedData.current.estado
        } else {
            return servicioData.nombre.length > 2 &&
            servicioData.descripcion.length > 3
        }
    };

    return (
        <div>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-3">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Nombre</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    placeholder="Nombre del servicio"
                                    aria-label="nombre"
                                    aria-describedby="nombre"
                                    id="nombre"
                                    value={servicioData.nombre}
                                    onChange={handleServicioData}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Descripción</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl 
                                    as="textarea"
                                    placeholder="Descripcion del servicio"
                                    aria-label="descripcion"
                                    aria-describedby="descripcion"
                                    id="descripcion"
                                    value={servicioData.descripcion}
                                    onChange={handleServicioData}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Checkbox 
                                        checked={servicioData.estado}
                                        id="estado"
                                        onChange={handleServicioData}
                                    />
                                </InputGroup.Prepend>
                                <FormControl 
                                    readOnly
                                    value="¿Disponible?"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col className="text-right">
                            <LoaderButton
                                variant="outline-primary"
                                isLoading={isSaving}
                                disabled={!handleEnableGuardarServicio()}
                                onClick={()=>handleGuardarServicio()}
                            > Guardar servicio</LoaderButton>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(FormServicio);