import React, { useState, useRef } from "react";
import { Row, Col, Spinner, InputGroup, FormControl, 
    Button, DropdownButton, Dropdown, Modal, Alert 
} from "react-bootstrap";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { BiPlusMedical } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import logo from "../img/Logo.svg";
import LoaderButton from "./loaderButton";
import UserFormEspecialista from "./userFormEspecialista";

const mapStateToProps = state => ({
    institucionId: state.institucionId
});

const mapDispatchToProps = dispatch => ({
    showError(error_data){
        dispatch({
            type: "SHOW_ERROR",
            error_data
        })
    },
});

const EspecialistasBlock = ({institucionId, showError}) => {

    const [showModalEspecialista, setShowModalEspecialista] = useState(false);
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [searchCriteria, setSearchCriteria] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isUsuarioSelected, setIsUsuarioSelected] = useState(false);
    const [usuarioSelectedId, setUsuarioSelectedId] = useState("");

    const tableData = useRef([]);
    const bootstrapTableMsg = useRef("No hay especialistas que mostrar");
    const columns = useRef([
        {
            dataField: "dni", 
            text: "Identificacion",
            style: {
                textTransform: "capitalize"
            },
            headerStyle: () => {
                return { width: '15%'}
            },
        },
        {
            dataField: "especialista", 
            text: "Especialista",
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "email", 
            text: "email",
        },
        {
            dataField: "telefono", 
            text: "Telefono",
            headerStyle: () => {
                return { width: '15%'}
            },
        }
    ]);

    const handleNuevoEspecialista = () => {
        setShowModalEspecialista(true);
    };

    const handleEditarEspecialista = () => {
        setShowModalEspecialista(true);
    };

    const handleEnableSearchBtn = () => {
        return searchCriteria.length > 3;
    };

    const handleErrors = (exception) => {
        console.log(exception);
        var msg = '';
        switch (exception) {
            case "DeleteUserException":
                msg = 'No fue posible eliminar el usuario';
                break;
            case "GetUserDataException":
                msg = 'No fue posible recuperar la información del usuario';
                break;
            default:
                msg = 'No fue posible realizar la acción, por favor intentelo de nuevo, si el problema persiste, comuniquese con nosotros'
                break;
        }
        const error_data= {
            show: true,
            msg: msg
        };
        showError(error_data)
    };

    const handleSearchUser = async() => {
        setIsSearching(true);
        setIsUsuarioSelected(false);
        setUsuarioSelectedId("");
        tableData.current = [];
        try {
            const qsp = {
                institucionId: institucionId,
                    get: ['usuarioId','identificacion','nombre','apellido','email','telefono'],
                    searchRol: 'especialista',
                    searchCriteria: searchCriteria.trim().toLowerCase()
            }
            const respuesta = await API.get("sanus", "/getUsers",{
                queryStringParameters: qsp
            });
            if(respuesta.fail){
                console.log(respuesta.description);
                const error = 'GetUserDataException';
                throw error;
            };
            for (let index = 0; index < respuesta.length; index++) {
                const element = respuesta[index];
                const tData = {
                    usuarioId: element.usuarioId,
                    dni: element.identificacion,
                    especialista: element.nombre + " " +element.apellido,
                    email: element.email,
                    telefono: element.telefono
                }
                tableData.current.push(tData)
            }
            setIsSearching(false);
        } catch (error) {
            handleErrors(error)
            setIsSearching(false);
        }
    };

    const handleOnSelect = (row, isSelect) => {
        if (isSelect){
            setUsuarioSelectedId(row.usuarioId);
            setIsUsuarioSelected(true);
        } else {
            setUsuarioSelectedId("");
            setIsUsuarioSelected(false);
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit:  true,
        onSelect: handleOnSelect
    };

    const handleCloseModalEspecialista = async() => {
        setShowModalEspecialista(false);
        if(usuarioSelectedId.length > 0){
            await handleSearchUser();
        }
    };

    const handleDeleteEspecialista = async() => {
        try{
            if(isUsuarioSelected){
                const result = await API.del("sanus", "/usuarios/"+usuarioSelectedId);
                if(result.fail){
                    console.log(result.description);
                    const error = 'DeleteUserException';
                    throw error;
                }
                handleSearchUser();
            }
        } catch (error){
            handleErrors(error);
        }
    };

    return (
        <div>
            <Modal 
                show = {showModalEspecialista}
                onHide = {()=>handleCloseModalEspecialista()}
                size = 'lg'
            >
                <Modal.Header closeButton>
                    Nuevo Especialista
                </Modal.Header>
                <Modal.Body>
                    <UserFormEspecialista 
                        setAlertType={setAlertType} 
                        setMessage={setMessage} 
                        usuarioSelectedId={usuarioSelectedId}
                        onFinish={handleCloseModalEspecialista}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {message.length > 0 ? <Alert variant={alertType} >{message}</Alert>:
                    <img
                        alt="SANUS Logo"
                        src={logo}
                        className="modal-footer-logo"
                    ></img>}
                </Modal.Footer>
            </Modal>
            <Row className="mt-3">
                <Col>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Nombre, apellido o ID del especialista"
                            aria-label="criteria"
                            aria-describedby="criteria"
                            value={searchCriteria}
                            onChange={e=>setSearchCriteria(e.target.value)}
                        />
                        <InputGroup.Append>
                            <LoaderButton 
                                isLoading={isSearching}
                                variant="outline-primary"
                                disabled={!handleEnableSearchBtn()}
                                onClick={()=>handleSearchUser()}
                            > Buscar</LoaderButton>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1 justify-content-end">
                <Col sm={'auto'} className="pr-1">
                    <Button 
                        onClick={()=>handleNuevoEspecialista()} 
                        title="Crear especialista" 
                        variant="outline-primary"
                    ><BiPlusMedical /></Button>
                </Col>
                <Col sm={'auto'} className="pl-0 pr-0">
                    <Button 
                        title="Editar especialista" 
                        variant="outline-warning" 
                        className="mr-1 ml-1"
                        disabled={!isUsuarioSelected}
                        onClick = {()=>handleEditarEspecialista()}
                    ><AiOutlineEdit /></Button>
                </Col>
                 <Col sm={'auto'} className="pl-1">
                     <DropdownButton 
                        variant="outline-danger" 
                        id="borrarPaciente" 
                        title={<MdDelete />}
                        disabled={!isUsuarioSelected}
                    >
                        <Dropdown.Item href="#/action-1">¿Está seguro?</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={()=>handleDeleteEspecialista()}
                        >Si, borra este paciente</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
            {isSearching ? 
                <Row>
                    <Col className="text-center mt-3">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="usuarioId"
                            data={tableData.current}
                            columns={columns.current}
                            pagination={paginationFactory(25)}
                            noDataIndication={bootstrapTableMsg.current}
                            selectRow={selectRow}
                        />
                    </Col>
                </Row>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(EspecialistasBlock);