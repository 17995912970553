import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Header from "./header";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import { BiMessageSquareError, BiCalendarAlt } from "react-icons/bi";
import { FaLaptopMedical, FaFileMedicalAlt, FaBriefcaseMedical } from "react-icons/fa";
import { RiHomeGearFill } from "react-icons/ri";
import Scheduler from "../components/scheduler";
import UnderConstruction from "../components/underConstruction";
import Tooltip from '@material-ui/core/Tooltip';
import Institucion from "./institucion";
import ConsultorioVirtual from "./consultorioVirtual";

const mapStateToProps = state => ({
    userId: state.userId,
    institucionAlias: state.institucionAlias,
    institucionId: state.institucionId
});

const mapDispatchToProps = dispatch => ({
    setLoginUser(auth_userid){
        dispatch({
            type: "USER_PERFORM_LOGIN",
            auth_userid
        })
    },
});

const Sanus = ({userId, institucionId, setLoginUser, institucionAlias}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showModalErrors, setShowModalErrors] = useState(false);
    const [page, setPage] = useState(0);
    
    const history = useHistory();

    const modalErrorTitle = useRef(<BiMessageSquareError size={30}/>);
    const modalErrorMsg = useRef("");

    const usuarioRol = useRef("");

    useEffect(()=>{
        const getUserRol = async(userId) => {
            try{
                const userRol = await API.get("sanus", "/usuarios/"+userId, {
                    queryStringParameters: {
                        get: ["rol"]
                    }
                });
                if(userRol.fail){
                    throw (userRol.description);
                } else {
                    usuarioRol.current = userRol.rol
                    setIsLoading(false);
                }
            } catch(error) {
                console.log(error);
                Auth.signOut();
                handleErrors("AppException");
                history.push("/login")
            }
        };
        const loginState = async() => {
            try{
                const currentUser = await Auth.currentAuthenticatedUser();
                setLoginUser(currentUser.username);
                getUserRol(currentUser.username);
            } catch (error){
                console.log(error);
                Auth.signOut();
                handleErrors("AppException");
                history.push("/login");
            }
        };        
        if(userId.length === 0){
            loginState();
        } else {
            if(!usuarioRol.current.length > 0){
                getUserRol(userId);
            } else {
                setIsLoading(false);
            }
        }
    },[userId, history, setLoginUser]);

    const handleErrors = (error) => {
        switch (error) {
            case "Exception":
                
                break;
        
            default:
                modalErrorMsg.current = modalErrorMsg.current = "Error general, intentalo de nuevo, si el problema persite comunicate con nosotros";
                break;
        }
        setShowModalErrors(true);
    };

    const renderPage = () => {
        switch (page) {
            case 0:
                return <Scheduler />
            case 1:
                return <ConsultorioVirtual />
            case 2:
                return <Institucion />
            default:
                return <UnderConstruction />
        }
    };
    
    const menu_manager = useRef([
        <Tooltip key={0} title="Calendario" placement="right" arrow>
            <MenuItem onClick={()=>setPage(0)} icon={<BiCalendarAlt size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={1} title="Consultorio virtual" placement="right" arrow>
            <MenuItem onClick={()=>setPage(1)} icon={<FaLaptopMedical size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={2} title={institucionAlias} placement="right" arrow>
            <MenuItem onClick={()=>setPage(2)} icon={<FaBriefcaseMedical size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={3} title="Documentos" placement="right" arrow>
            <MenuItem onClick={()=>setPage(4)} icon={<FaFileMedicalAlt size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={4} title="Configuración" placement="right" arrow>
            <MenuItem onClick={()=>setPage(4)} icon={<RiHomeGearFill size={20}/>}></MenuItem>
        </Tooltip>
    ]);

    const menu_coordinador = useRef([
        <Tooltip key={0} title="Calendario" placement="right" arrow>
            <MenuItem onClick={()=>setPage(0)} icon={<BiCalendarAlt size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={1} title="Consultorio virtual" placement="right" arrow>
            <MenuItem onClick={()=>setPage(1)} icon={<FaLaptopMedical size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={2} title={institucionAlias} placement="right" arrow>
            <MenuItem onClick={()=>setPage(2)} icon={<FaBriefcaseMedical size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={3} title="Documentos" placement="right" arrow>
            <MenuItem onClick={()=>setPage(4)} icon={<FaFileMedicalAlt size={20}/>}></MenuItem>
        </Tooltip>,
    ]);

    const menu_especialista = useRef([
        <Tooltip key={0} title="Calendario" placement="right" arrow>
            <MenuItem onClick={()=>setPage(0)} icon={<BiCalendarAlt size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={1} title="Consultorio virtual" placement="right" arrow>
            <MenuItem onClick={()=>setPage(1)} icon={<FaLaptopMedical size={20}/>}></MenuItem>
        </Tooltip>,
        <Tooltip key={3} title="Documentos" placement="right" arrow>
            <MenuItem onClick={()=>setPage(4)} icon={<FaFileMedicalAlt size={20}/>}></MenuItem>
        </Tooltip>,
    ]);
    
    return (
        <div>
            <Modal 
                show={showModalErrors}
                centered
                onHide={()=>setShowModalErrors(false)}
            >
                <Modal.Header closeButton>{modalErrorTitle.current}</Modal.Header>
                <Modal.Body>{modalErrorMsg.current}</Modal.Body>
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-5 pt-5">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            : 
                <div>
                    <Row>
                        <Col>
                            <Header />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={"auto"}>
                            <ProSidebar className="md" collapsed={true} >
                                <SidebarHeader>

                                </SidebarHeader>
                                <SidebarContent>
                                    <Menu iconShape="circle">
                                        {usuarioRol.current === 'manager' && menu_manager.current.map((k)=>{
                                            return k;
                                        })}
                                        {usuarioRol.current === 'coordinador' && menu_coordinador.current.map((k)=>{
                                            return k;
                                        })}
                                        {usuarioRol.current === 'especialista' && menu_especialista.current.map((k)=>{
                                            return k;
                                        })}
                                        {/* <Tooltip title="Calendario" placement="right" arrow>
                                            <MenuItem onClick={()=>setPage(0)} icon={<BiCalendarAlt size={20}/>}></MenuItem>
                                        </Tooltip>
                                        <Tooltip title="Consultorio virtual" placement="right" arrow>
                                            <MenuItem onClick={()=>setPage(1)} icon={<FaLaptopMedical size={20}/>}></MenuItem>
                                        </Tooltip>
                                        <Tooltip title={institucionAlias} placement="right" arrow>
                                            <MenuItem onClick={()=>setPage(2)} icon={<FaBriefcaseMedical size={20}/>}></MenuItem>
                                        </Tooltip>
                                        <Tooltip title="Documentos" placement="right" arrow>
                                            <MenuItem onClick={()=>setPage(4)} icon={<FaFileMedicalAlt size={20}/>}></MenuItem>
                                        </Tooltip>
                                        <Tooltip title="Configuración" placement="right" arrow>
                                            <MenuItem onClick={()=>setPage(4)} icon={<RiHomeGearFill size={20}/>}></MenuItem>
                                        </Tooltip> */}
                                    </Menu>
                                </SidebarContent>
                                <SidebarFooter>
                                    <Col className="text-center">
                                        <a href="https://www.gvalor.co" rel="noopener noreferrer" target="_blank"><i className="prosidebar-footer-text">© GVIS.</i></a>
                                    </Col>
                                </SidebarFooter>
                            </ProSidebar>
                        </Col>
                        <Col>
                            {institucionId ? renderPage() : 
                            <Row>
                                <Col className="text-center mt-3">
                                    <Spinner animation="border" variant="primary" />
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect (mapStateToProps, mapDispatchToProps)(Sanus);