import './App.css';
import { Container } from "react-bootstrap";
import { Provider } from "react-redux";
import Routes from "./Routes";
import store from "./store"

function App() {
  return (
    <Container fluid>
      <Provider store={store}>
        <Routes />
      </Provider>
    </Container>
  );
}

export default App;